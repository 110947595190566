import * as React from "react";

import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { InputBase } from "@mui/material";
import { useState } from "react";
import Box from "@mui/material/Box";
import AutoModeIcon from "@mui/icons-material/AutoMode";

export default function AiCard({ children, objectKey, requestRevision }) {
  const [message, setMessage] = useState("");

  const handleAiRequest = (event) => {
    if (event.type === "keydown" && event.key === "Enter") {
      requestRevision(objectKey, message);
      setMessage("");
    }
    if (event.type === "click") {
      requestRevision(objectKey, "refresh");
      setMessage("");
    }
  };

  return (
    <Box>
      <CardContent sx={{ p: 0 }}>{children}</CardContent>
      <CardActions disableSpacing sx={{ m: "0", p: "8px 0" }}>
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
          }}
          elevation={2}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Hoe kan ik dit voor je verbeteren?"
            onKeyDown={handleAiRequest}
            onChange={(event) => setMessage(event.target.value)}
            value={message}
          />

          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            color="primary"
            sx={{ p: "10px" }}
            onClick={handleAiRequest}
          >
            <AutoModeIcon />
          </IconButton>
        </Paper>
      </CardActions>
    </Box>
  );
}
