import PageContainer from "../Components/UI/PageContainer";
import DataTable from "../Components/UI/DataTable";
import headers from "../Utils/Fields/missingOfferFields";
import endpoint from "../Utils/endpoint";
import LinkIcon from "@mui/icons-material/Link";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
import { useParams } from "react-router-dom";
import DownloadingIcon from "@mui/icons-material/Downloading";
import BaseDialog from "../Components/UI/BaseDialog";
import { useEffect, useState } from "react";
import AutoComplete from "../Components/UI/AutoComplete";
import { useAppDataContext } from "../Hooks/AppData/useAppDataContext";
import { useFetchTableData } from "../Hooks/AppData/useFetchTableData";
import _ from "lodash";
import { Checkbox, SpeedDial, SpeedDialIcon } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useSnackbar } from "notistack";
import { useAxios } from "../Hooks/useAxios";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";

const MissingOffersPage = () => {
  const { websiteId } = useParams();
  const { appData } = useAppDataContext();
  const { fetchTableData } = useFetchTableData();
  const { enqueueSnackbar } = useSnackbar();
  const { Axios } = useAxios();

  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openOfferDialog, setOpenOfferDialog] = useState({
    open: false,
    row: null,
  });
  const [disableSave, setDisableSave] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState({});
  const [linkOptions, setLinkOptions] = useState([]);
  const [savingOffer, setSavingOffer] = useState(false);

  useEffect(() => {
    async function getWebsiteCategories() {
      const categories = await fetchTableData(
        `${endpoint.monitoredCategories}/website/${websiteId}`,
        undefined,
        true,
      );

      const transformedCategories = _.map(categories, (category) => ({
        label: category.category,
        value: category._id,
      }));

      setOptions(transformedCategories);
    }
    getWebsiteCategories();
    // eslint-disable-next-line
  }, [appData]);

  async function addToIgnoreList(row) {
    const response = await Axios(
      `${endpoint.ignoreList}/${websiteId}`,
      "POST",
      row,
    );

    if (response.status === 200) {
      await fetchTableData(
        `${endpoint.monitoredCategories}/website/${websiteId}/missingOffers`,
      );
      enqueueSnackbar("Product succesvol toegevoegd aan negeerlijst", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Er ging iets mis met toevoegen", {
        variant: "error",
      });
    }
  }

  useEffect(() => {
    async function getArticleOptions() {
      const response = await Axios(
        `${endpoint.monitoredCategories}/website/${websiteId}/articleLinkOptions`,
        "GET",
      );

      if (response && response.status === 200) setLinkOptions(response.data);
    }

    getArticleOptions();
  }, []);

  async function handleOfferSave() {
    setSavingOffer(true);
    const linkInfo = openOfferDialog.row;
    delete linkInfo._id;
    linkInfo.articleId = selectedOffer._id;

    const response = await Axios(
      `${endpoint.linkedOfferList}/${websiteId}`,
      "POST",
      linkInfo,
    );

    if (response && response.status === 200) {
      await fetchTableData(
        `${endpoint.monitoredCategories}/website/${websiteId}/missingOffers`,
      );
      enqueueSnackbar(`Producten zijn succesvol gekoppeld`, {
        variant: "success",
      });
      setOpenOfferDialog({ open: false, row: null });
    } else {
      enqueueSnackbar(`Er ging iets mis met het koppelen`, {
        variant: "error",
      });
    }

    setSavingOffer(false);
  }

  const customRowActions = [
    {
      label: "Aanbod koppelen",
      icon: <LinkIcon />,
      onClick: (row) => {
        setOpenOfferDialog({ open: true, row: row });
      },
    },
    {
      label: "Aanbod negeren",
      icon: <NotificationsPausedIcon />,
      onClick: async (row) => {
        await addToIgnoreList(row);
      },
    },
  ];

  const tableActions = [
    {
      label: "Aanbod verwijderen",
      icon: <DeleteIcon />,
      onClick: () => setOpenDeleteDialog(true),
    },
  ];

  function afterClose() {
    setOpenDialog(false);
  }

  async function handleSave() {
    enqueueSnackbar("Categorie(ën) worden op de achtergrond bijgewerkt", {
      variant: "success",
    });

    setOpenDialog(false);

    const response = await Axios(
      `${endpoint.monitoredCategories}/website/${websiteId}/monitorCategory`,
      "POST",
      selectedOption,
    );

    if (response && response.status === 200) {
      await fetchTableData(
        `${endpoint.monitoredCategories}/website/${websiteId}/missingOffers`,
      );

      enqueueSnackbar(response.data, {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Er ging iets mis met het bijwerken van de categorieën", {
        variant: "error",
      });
    }
  }

  function onSelect(value) {
    setSelectedOption(value);
  }

  async function handleDelete() {
    const response = await Axios(
      `${endpoint.monitoredCategories}/website/${websiteId}/deleteMonitoredCategory`,
      "POST",
      selectedOption,
    );

    if (response && response.status === 200) {
      await fetchTableData(
        `${endpoint.monitoredCategories}/website/${websiteId}/missingOffers`,
      );

      enqueueSnackbar("Aabod is succesvol verwijderd", {
        variant: "success",
      });
      setOpenDeleteDialog(false);
    } else {
      enqueueSnackbar("Er ging iets mis met het verwijderen van het aanbod", {
        variant: "error",
      });
    }
  }

  return (
    <PageContainer>
      <DataTable
        headers={headers}
        initialSort={{ field: "productName", sort: "asc" }}
        title="Winkelaanbod overzicht"
        endpoint={`${endpoint.monitoredCategories}/website/${websiteId}/missingOffers`}
        autoPageSize
        autoHeight
        allowDuplicate={false}
        density="compact"
        allowEdit={false}
        allowDelete={false}
        onRowClick={(params) => {
          window.open(params.row.url, "_blank");
        }}
        customRowActions={customRowActions}
        tableActions={tableActions}
        displayFilter
      />
      <SpeedDial
        onClick={() => setOpenDialog(true)}
        ariaLabel="SpeedDial"
        sx={{ position: "fixed", bottom: 32, right: 32 }}
        icon={<SpeedDialIcon openIcon={<UpdateIcon />} />}
      ></SpeedDial>
      <BaseDialog
        openDialog={openDialog}
        title="Categorie updaten"
        content={
          <AutoComplete
            label="Selecteer een categorie"
            options={options}
            onSelect={onSelect}
            multiple
            // disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
          />
        }
        afterClose={afterClose}
        disableHandleSave={disableSave || selectedOption.length === 0}
        handleSave={handleSave}
        width={800}
      />
      <BaseDialog
        openDialog={openDeleteDialog}
        title="Aanbod verwijderen"
        content={
          <AutoComplete
            label="Selecteer een categorie"
            options={options}
            onSelect={onSelect}
            multiple
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
          />
        }
        afterClose={() => setOpenDeleteDialog(false)}
        disableHandleSave={disableSave || selectedOption.length === 0}
        handleSave={handleDelete}
        width={800}
      />
      <BaseDialog
        openDialog={openOfferDialog.open}
        title="Aanbod koppelen"
        content={
          <AutoComplete
            label="Zoek een artikel"
            noOptionsText="Geen artikelen gevonden"
            options={linkOptions || []}
            onSelect={(selected) => {
              setSelectedOffer(selected);
            }}
            getOptionLabel={(option) => option.articleName || ""}
            getOptionValue={(option) => option._id || ""}
            isOptionEqualToValue={(option, value) => option._id === value._id}
          />
        }
        afterClose={() => setOpenOfferDialog({ open: false, row: null })}
        handleSave={handleOfferSave}
        width={800}
        disableHandleSave={
          savingOffer || linkOptions.length === 0 || !selectedOffer
        }
        loading={savingOffer}
      />
    </PageContainer>
  );
};

export default MissingOffersPage;
