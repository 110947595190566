import Box from "@mui/material/Box";
import { Stack } from "@mui/system";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { InputAdornment } from "@mui/material";
import endpoint from "../Utils/endpoint";
import { useParams } from "react-router-dom";
import { useAxios } from "../Hooks/useAxios";

const MetaDetails = ({ article, refreshArticle }) => {
  const { Axios } = useAxios();
  const { articleId, websiteId } = useParams();

  const [contentDetails, setContentDetails] = useState({
    productDescription: "",
    slug: "",
    metaDescription: "",
    recommendedRetailPrice: "",
  });

  const [characterCount, setCharacterCount] = useState({
    productDescription: 0,
    slug: 0,
    metaDescription: 0,
  });

  useEffect(() => {
    setContentDetails((prevContentDetails) => ({
      ...prevContentDetails,
      slug: article.slug || "",
      productDescription: article.productDescription || "",
      metaDescription: article.metaDescription || "",
      recommendedRetailPrice: article.recommendedRetailPrice
        ? article.recommendedRetailPrice.toString().replace(".", ",")
        : "",
    }));
  }, [article]);

  async function saveDataInDatabase() {
    const updatedContentDetails = {
      ...contentDetails,
      recommendedRetailPrice: contentDetails.recommendedRetailPrice
        ? parseFloat(
            contentDetails.recommendedRetailPrice.replace(",", "."),
          ).toFixed(2)
        : "",
    };

    const response = await Axios(
      `${endpoint.contentArticle}/website/${websiteId}/article/${articleId}`,
      "POST",
      updatedContentDetails,
    );

    if (response && response.status === 200) refreshArticle();
  }

  useEffect(() => {
    setCharacterCount((prevCount) => ({
      ...prevCount,
      productDescription: contentDetails.productDescription.length,
    }));
  }, [contentDetails.productDescription]);

  useEffect(() => {
    const words = contentDetails.slug.split("-");
    setCharacterCount((prevCount) => ({
      ...prevCount,
      slug: words.filter((word) => word !== "").length,
    }));
  }, [contentDetails.slug]);

  useEffect(() => {
    setCharacterCount((prevCount) => ({
      ...prevCount,
      metaDescription: contentDetails.metaDescription.length,
    }));
  }, [contentDetails.metaDescription]);

  return (
    <Box>
      <Stack spacing={2}>
        <TextField
          label="Adviesprijs"
          onBlur={saveDataInDatabase}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              saveDataInDatabase();
              event.target.blur();
            }
          }}
          onChange={(event) =>
            setContentDetails((prevState) => ({
              ...prevState,
              recommendedRetailPrice: event.target.value,
            }))
          }
          value={contentDetails.recommendedRetailPrice}
        />

        <TextField
          label="Slug"
          variant="outlined"
          onBlur={saveDataInDatabase}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              saveDataInDatabase();
              event.target.blur();
            }
          }}
          onChange={(event) =>
            setContentDetails((prevState) => ({
              ...prevState,
              slug: event.target.value.replace(/\//g, "-").replace(/\s+/g, "-"),
            }))
          }
          value={contentDetails.slug}
          helperText={`${characterCount.slug} woorden`}
          InputProps={{
            startAdornment: <InputAdornment position="start">/</InputAdornment>,
          }}
        />

        <TextField
          label="Metabeschrijving"
          multiline
          onBlur={saveDataInDatabase}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              saveDataInDatabase();
              event.target.blur();
            }
          }}
          rows={2}
          helperText={`${characterCount.metaDescription}/155 tekens`}
          onChange={(event) =>
            setContentDetails((prevState) => ({
              ...prevState,
              metaDescription: event.target.value,
            }))
          }
          value={contentDetails.metaDescription}
          error={characterCount.metaDescription > 155}
        />

        <TextField
          label="Korte product omschrijving"
          multiline
          onBlur={saveDataInDatabase}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              saveDataInDatabase();
              event.target.blur();
            }
          }}
          rows={3}
          helperText={`${characterCount.productDescription}/250 tekens`}
          onChange={(event) =>
            setContentDetails((prevState) => ({
              ...prevState,
              productDescription: event.target.value,
            }))
          }
          value={contentDetails.productDescription}
          error={characterCount.productDescription > 250}
        />
      </Stack>
    </Box>
  );
};

export default MetaDetails;
