import React from "react";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from "@mui/icons-material/Launch";
import { useNavigate } from "react-router-dom";

const LinkButton = ({ value, navigateTo, size }) => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.stopPropagation();
    if (value) {
      window.open(value, "_blank");
    } else if (navigate) {
      navigate(navigateTo);
    }
  };

  return (
    <IconButton onClick={handleClick}>
      <LaunchIcon sx={{ fontSize: size ? size : "extraLarge" }} />
    </IconButton>
  );
};

export default LinkButton;
