import { useState } from "react";
import DataTable from "./UI/DataTable";
import { DialogModes } from "../Utils/DialogModes";
import headers from "../Utils/Fields/specificationFields";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import endpoint from "../Utils/endpoint";
import AddEditSpecificationDialog from "./Dialogs/AddEditSpecificationDialog";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import { useAxios } from "../Hooks/useAxios";
import { useSnackbar } from "notistack";
import { useFetchTableData } from "../Hooks/AppData/useFetchTableData";
import EditIcon from "@mui/icons-material/Edit";

const ProductSpecifications = ({ refreshArticle }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { websiteId, articleId } = useParams();
  const { Axios } = useAxios();
  const { enqueueSnackbar } = useSnackbar();
  const { fetchTableData } = useFetchTableData();

  function handleClose() {
    setOpenDialog(false);
  }

  const [patchCopyData, setPatchCopyData] = useState({});

  const handleEditCopy = (rowData) => {
    setPatchCopyData(rowData);
    setOpenDialog(true);
  };

  async function handleSpecifications(list) {
    const response = await Axios(
      `${endpoint.specifications}/website/${websiteId}/article/${articleId}/bulk/${list}`,
      "POST",
    );

    if (response?.status === 200) {
      await fetchTableData(
        `${endpoint.specifications}/website/${websiteId}/article/${articleId}`,
        "specifications",
      );
      enqueueSnackbar(`Specificatie set succesvol toegevoegd`, {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Er ging iets mis met het ophalen van de specificaties", {
        variant: "error",
      });
    }
  }

  const tableActions = [
    {
      label: "Productspecificaties 1 toevoegen",
      icon: <SettingsIcon />,
      onClick: () => handleSpecifications("spec1"),
    },
  ];

  return (
    <Box>
      <DataTable
        headers={headers}
        title="Specificaties"
        singular="Specificatie"
        autoPageSize
        autoHeight
        onEditCopyRow={handleEditCopy}
        displayAddIcon
        allowDuplicate={false}
        endpoint={`${endpoint.specifications}/website/${websiteId}/article/${articleId}`}
        density="compact"
        customAppDataName="specifications"
        tableActions={tableActions}
        afterDelete={() => refreshArticle()}
      />
      <AddEditSpecificationDialog
        openDialog={openDialog}
        afterClose={handleClose}
        patchData={patchCopyData.row}
        dialogMode={DialogModes[patchCopyData.mode]}
        afterSave={() => refreshArticle()}
      />
    </Box>
  );
};

export default ProductSpecifications;
