import { Stack } from "@mui/system";
import Button from "@mui/material/Button";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import ArticleContentFullScreenDialog from "./ArticleContentFullScreenDialog";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { useAxios } from "../Hooks/useAxios";
import endpoint from "../Utils/endpoint";
import { useParams } from "react-router-dom";
import AiDialog from "./AiDialog";
import { useSnackbar } from "notistack";
import aiMessageOptions from "../Utils/aiMessageOptions";

const ContentAiButtons = ({
  article,
  articleId,
  refreshArticle,
  displayLoader,
}) => {
  const [openContentDialog, setOpenContentDialog] = useState(false);
  const [insufficientContent, setInsufficientContent] = useState(true);
  const { Axios } = useAxios();
  const { websiteId } = useParams();
  const [openAiDialog, setOpenAiDialog] = useState(false);
  const [initialAiResponse, setInitialAiResponse] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [loadingAccordion, setLoadingAccordion] = useState({
    [aiMessageOptions.description]: true,
    [aiMessageOptions.featuresAndSpecifications]: true,
    [aiMessageOptions.longProductDescription]: true,
  });

  useEffect(() => {
    if (article.productContent) {
      if (article.productContent.length === 0) {
        setInsufficientContent(true);
      } else {
        setInsufficientContent(false);
      }
    }
  }, [article]);

  async function fillWithAi() {
    displayLoader(true);

    await Promise.all([
      getAiData(aiMessageOptions.description),
      getAiData(aiMessageOptions.featuresAndSpecifications),
      getAiData(aiMessageOptions.longProductDescription),
    ]);
  }

  async function getAiData(params) {
    setLoadingAccordion((prevState) => ({
      ...prevState,
      [aiMessageOptions[params]]: true,
    }));

    const response = await Axios(
      `${endpoint.ai}/website/${websiteId}/article/${articleId}/ai/${params}`,
      "GET",
    );

    if (response && response.status === 200) {
      setInitialAiResponse((prevState) => {
        const updatedInitialAiResponse = { ...prevState };
        const keyArray = Object.keys(response.data);

        keyArray.forEach((key) => {
          updatedInitialAiResponse[key] = response.data[key];
        });

        return updatedInitialAiResponse;
      });

      setLoadingAccordion((prevState) => ({
        ...prevState,
        [aiMessageOptions[params]]: false,
      }));

      setOpenAiDialog(true);
      displayLoader(false);
    } else {
      enqueueSnackbar("Er ging iets mis met het genereren van de ai content", {
        variant: "error",
      });
      displayLoader(false);
    }
  }

  function afterClose() {
    setOpenAiDialog(false);
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography variant="h2" sx={{ fontSize: "28px" }}>
        Product informatie
      </Typography>
      <Box>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            startIcon={<AssignmentIcon />}
            onClick={() => setOpenContentDialog(true)}
          >
            Content
          </Button>
          <Button
            variant="contained"
            endIcon={<AutoFixHighIcon />}
            onClick={fillWithAi}
            disabled={insufficientContent}
          >
            Ai
          </Button>
        </Stack>
        <ArticleContentFullScreenDialog
          openDialog={openContentDialog}
          afterClose={() => setOpenContentDialog(false)}
          article={article}
          articleId={articleId}
          refreshArticle={refreshArticle}
        />
        <AiDialog
          openDialog={openAiDialog}
          afterClose={afterClose}
          displayLoader={displayLoader}
          initialAiResponse={initialAiResponse}
          loadingAccordion={loadingAccordion}
          refreshArticle={refreshArticle}
        />
      </Box>
    </Box>
  );
};

export default ContentAiButtons;
