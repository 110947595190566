import ErrorIcon from "@mui/icons-material/Error";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import endpoint from "../Utils/endpoint";
import { useFetchTableData } from "../Hooks/AppData/useFetchTableData";
import { useParams } from "react-router-dom";
import { useAxios } from "../Hooks/useAxios";

const ProductPrice = ({
  params,
  addToCheckedPrices,
  alreadyCheckedPrices,
  refreshArticle,
}) => {
  const { fetchTableData } = useFetchTableData();
  const { Axios } = useAxios();
  const { websiteId, articleId } = useParams();
  const [priceValue, setPriceValue] = useState(params.row.price);
  const [loading, setLoading] = useState(false);

  async function getPrice() {
    setLoading(true);
    const response = await Axios(
      `${endpoint.affiliates}/website/${websiteId}/article/${articleId}/${params.row._id}`,
      "GET",
    );

    if (response && response.status === 200) {
      refreshArticle();
      setPriceValue(response.data.price);
    } else {
      console.log("Price could not be found!");
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
    addToCheckedPrices(params.row._id);
  }

  useEffect(() => {
    if (!params.row.date && !alreadyCheckedPrices[params.row._id]) {
      getPrice();
    }
  }, [params.row]);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {params.row.price || priceValue ? (
        <span>
          {new Intl.NumberFormat("nl-NL", {
            style: "currency",
            currency: "EUR",
          }).format(params.row.price || priceValue)}
        </span>
      ) : loading ? (
        <CircularProgress size="1.5rem" />
      ) : params.row.price === null ? (
        <ErrorIcon style={{ color: "green" }} />
      ) : (
        <ErrorIcon style={{ color: "red" }} />
      )}
    </Box>
  );
};

export default ProductPrice;
