const fields = [
  {
    field: "websiteName",
    required: true,
    label: "Websitenaam",
    col: 6,
    display: "textfield",
  },
  {
    field: "domain",
    required: true,
    label: "Domein",
    col: 6,
    display: "textfield",
  },
  {
    field: "apiKey",
    required: true,
    label: "Api sleutel",
    value: "",
    col: 12,
    display: "textfield",
  },
];

export default fields;
