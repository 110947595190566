import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";

const CustomAutocomplete = ({
  value,
  onChange,
  onInputChange,
  options,
  label,
  freeSolo = false,
  name,
  readOnly = false,
  disabled = false,
}) => {
  return (
    <Autocomplete
      freeSolo={freeSolo}
      value={value}
      onChange={onChange}
      onInputChange={onInputChange}
      options={options}
      readOnly={readOnly}
      disabled={disabled}
      noOptionsText="Geen opties gevonden"
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin="normal"
          variant="outlined"
          name={name}
        />
      )}
    />
  );
};

export default CustomAutocomplete;
