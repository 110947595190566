const fields = [
  {
    field: "categoryDisplayName",
    required: true,
    label: "Categorienaam",
    col: 12,
    display: "textfield",
  },
  {
    field: "singular",
    required: true,
    label: "Categorienaam enkelvoud",
    col: 12,
    display: "textfield",
  },
];

export default fields;
