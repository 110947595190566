import React, { useEffect, useState } from "react";
import UploadImageDialog from "./UploadImageDialog";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import endpoint from "../Utils/endpoint";
import { useAxios } from "../Hooks/useAxios";
import { useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import Slide from "@mui/material/Slide";
import { useSnackbar } from "notistack";

const ImageUploadBlock = ({ article, refreshArticle, displayLoader }) => {
  const { websiteId, articleId } = useParams();
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [imageList, setImageList] = useState([]);
  const { Axios } = useAxios();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  async function getImagesFromServer() {
    setLoading(true);
    const images = await Axios(
      `${endpoint.contentArticle}/website/${websiteId}/article/${articleId}`,
      "GET",
    );

    const convertImagesToFiles = images?.data?.map((image) => {
      const imageDataArray = image.data.data;

      const uint8Array = new Uint8Array(imageDataArray);

      const blob = new Blob([uint8Array], { type: image.contentType });

      const fileObject = new File([blob], image.fileName, {
        type: image.contentType,
      });

      fileObject.userId = image.userId;
      fileObject.websiteId = image.websiteId;
      fileObject.articleId = image.articleId;
      fileObject._id = image._id;

      return fileObject;
    });

    setImageList(convertImagesToFiles);
    setLoading(false);
  }

  useEffect(() => {
    getImagesFromServer();
  }, []);

  function handleUpload() {
    setOpenImageDialog(!openImageDialog);
  }

  function handleEdit() {
    setOpenImageDialog(!openImageDialog);
  }

  const [imageMetaData, setImageMetaData] = useState([]);

  useEffect(() => {
    const imageData = imageList.map((image) => {
      if (image._id) {
        return {
          userId: image.userId,
          articleId: image.articleId,
          websiteId: image.websiteId,
          _id: image._id,
        };
      } else {
        return {
          type: "new",
        };
      }
    });
    setImageMetaData(imageData);
  }, [imageList]);

  async function saveImagesInDatabase(images) {
    displayLoader(true);

    const imageData = images.map((image) => {
      if (image._id) {
        return {
          userId: image.userId,
          articleId: image.articleId,
          websiteId: image.websiteId,
          _id: image._id,
        };
      } else {
        return {
          type: "new",
        };
      }
    });

    enqueueSnackbar("Afbeeldingen opslaan...", { variant: "default" });

    const pageData = new FormData();

    pageData.append("imageMetaData", JSON.stringify(imageData));

    images.forEach((image, index) => {
      pageData.append("image", image);
    });

    const response = await Axios(
      `${endpoint.contentArticle}/website/${websiteId}/article/${articleId}`,
      "POST",
      pageData,
    );

    if (response?.status === 200) {
      enqueueSnackbar("Afbeeldingen succesvol opgeslagen", {
        variant: "success",
      });
      getImagesFromServer();
      refreshArticle();
    } else {
      enqueueSnackbar("Er ging iets mis met het opslaan van de afbeeldingen", {
        variant: "error",
      });
    }
    displayLoader(false);
  }

  async function getImages(images) {
    setImageList(images);
    await saveImagesInDatabase(images);
  }

  function resetIndex() {
    setDisplayIndex(0);
  }

  const [displayIndex, setDisplayIndex] = useState(0);

  return (
    <div>
      {imageList.length === 0 ? (
        <Box sx={{ paddingBottom: "5px" }}>
          <Paper
            onClick={() => handleUpload()}
            sx={{
              cursor: "pointer",
              width: "100%",
              height: "400px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            elevation={2}
          >
            {loading ? (
              <Skeleton
                variant="rounded"
                width="100%"
                height="100%"
                animation="wave"
              />
            ) : (
              <Box>
                <ImageNotSupportedIcon
                  sx={{ transform: "scale(5.5)", color: "primary.main" }}
                />
              </Box>
            )}
          </Paper>
        </Box>
      ) : (
        <Box>
          <Box onClick={handleEdit} sx={{ marginBottom: 1 }}>
            <Paper
              sx={{
                width: "100%",
                height: "400px",
                overflow: "hidden",
                borderRadius: "10px",
                borderColor: "grey.500",
              }}
              elevation={2}
            >
              <img
                src={URL.createObjectURL(imageList[displayIndex])}
                alt="Preview"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  display: "block",
                  margin: "0 auto",
                  cursor: "pointer",
                }}
              />
            </Paper>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "spaceBetween",
            }}
          >
            {imageList.length > 1 &&
              imageList.map((image, index) => {
                return (
                  <Slide key={index} in={true} direction="up">
                    <Box
                      sx={{
                        width: "100px",
                        height: "100px",
                        overflow: "hidden",
                        border: 1,
                        borderRadius: "10px",
                        borderColor:
                          displayIndex === index ? "primary.main" : "grey.500",
                        marginRight: 1,
                        cursor: "pointer",
                      }}
                      onClick={() => setDisplayIndex(index)}
                      key={index}
                    >
                      <img
                        src={URL.createObjectURL(image)}
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          display: "block",
                          margin: "0 auto",
                        }}
                      />
                    </Box>
                  </Slide>
                );
              })}
          </Box>
        </Box>
      )}
      <UploadImageDialog
        openDialog={openImageDialog}
        getImages={getImages}
        imageList={imageList}
        resetIndex={resetIndex}
      />
    </div>
  );
};

export default ImageUploadBlock;
