import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useAuthContext } from "./Hooks/Auth/useAuthContext";
import { nlNL } from "@mui/material/locale";
import SignupPage from "./Pages/Auth/SignupPage";
import LoginPage from "./Pages/Auth/LoginPage";
import IndexPage from "./Pages/WebsitesOverviewPage";
import DrawerSideBar from "./Components/UI/Navigation/DrawerSideBar";
import WebsiteSettingsPage from "./Pages/WebsiteSettingsPage";
import ArticleOverviewPage from "./Pages/ArticleOverviewPage";
import ArticleEditPage from "./Pages/ArticleEditPage";
import WebsiteCategoriesOverview from "./Pages/WebsiteCategoriesOverviewPage";
import WebsiteCategoryPage from "./Pages/WebsiteCategoryPage";
import WebsiteMonitoringOverview from "./Pages/WebsiteMonitoringOverview";
import MissingOffersPage from "./Pages/MissingOffersPage";
import CategoryMonitoringSettings from "./Pages/CategoryMonitoringSettings";
import IgnoreListPage from "./Pages/IgnoreListPage";
import LinkedOffersListPage from "./Pages/LinkedOffersListPage";

const darkTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#ff5733",
    },
    secondary: {
      main: "#2a4365",
      contrastText: "white",
    },
  },
  locale: nlNL,
  typography: {
    h1: {
      fontSize: "3rem",
      fontWeight: 800,
      marginBottom: 12,
      "@media (max-width:600px)": {
        fontSize: "1.4rem",
      },
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 700,
      marginBottom: 12,
    },
    h3: {
      fontSize: "2.2rem",
      fontWeight: 600,
      marginBottom: 12,
    },
    h4: {
      fontSize: "2.0rem",
      fontWeight: 500,
      marginBottom: 12,
    },
  },
});

function App() {
  const { user } = useAuthContext();
  return (
    <ThemeProvider theme={darkTheme}>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              user ? (
                <DrawerSideBar>
                  <IndexPage />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            exact
            path="/website/:websiteId"
            element={
              user ? (
                <DrawerSideBar>
                  <ArticleOverviewPage />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            exact
            path="/website/:websiteId/article/:articleId"
            element={
              user ? (
                <DrawerSideBar>
                  <ArticleEditPage />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            exact
            path="/websites"
            element={
              user ? (
                <DrawerSideBar>
                  <WebsiteSettingsPage />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            exact
            path="/website-categories-overview"
            element={
              user ? (
                <DrawerSideBar>
                  <WebsiteCategoriesOverview />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            exact
            path="/website/:websiteId/categories"
            element={
              user ? (
                <DrawerSideBar>
                  <WebsiteCategoryPage />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            exact
            path="/monitoring"
            element={
              user ? (
                <DrawerSideBar>
                  <WebsiteMonitoringOverview />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            exact
            path="/website/:websiteId/monitoring"
            element={
              user ? (
                <DrawerSideBar>
                  <MissingOffersPage />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            exact
            path="/website/:websiteId/monitored-categories"
            element={
              user ? (
                <DrawerSideBar>
                  <CategoryMonitoringSettings />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            exact
            path="/website/:websiteId/ignoreList"
            element={
              user ? (
                <DrawerSideBar>
                  <IgnoreListPage />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            exact
            path="/website/:websiteId/linkList"
            element={
              user ? (
                <DrawerSideBar>
                  <LinkedOffersListPage />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/login"
            element={!user ? <LoginPage /> : <Navigate to="/" />}
          />
          <Route
            path="/accountaanmaken"
            element={!user ? <SignupPage /> : <Navigate to="/" />}
          />
          <Route path="/*" element={<Navigate to="/login" />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
