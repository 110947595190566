import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import CheckIcon from "@mui/icons-material/Check";
import { useAxios } from "../Hooks/useAxios";
import endpoint from "../Utils/endpoint";
import { useParams } from "react-router-dom";

export default function ArticleTitle({ article, refreshArticle }) {
  const { websiteId, articleId } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(article?.articleName || "");
  const { Axios } = useAxios();

  useEffect(() => {
    setTitle(article.articleName);
  }, [article]);

  const handleEditModeToggle = async () => {
    if (editMode) {
      await Axios(
        `${endpoint.contentArticle}/website/${websiteId}/article/${articleId}`,
        "POST",
        { articleName: title },
      );
      refreshArticle();
    }
    setEditMode(!editMode);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleEnterKeyPress = async (event) => {
    if (event.key === "Enter") {
      await handleEditModeToggle();
    }
  };

  return (
    <Box>
      {!editMode ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h1" sx={{ fontSize: "2.4rem", marginBottom: 0 }}>
            {title || "Artikel bewerken"}
          </Typography>
          <IconButton sx={{ marginLeft: 1 }} onClick={handleEditModeToggle}>
            <EditIcon fontSize="medium" />
          </IconButton>
        </Box>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            variant="standard"
            value={title}
            onChange={handleTitleChange}
            onKeyDown={handleEnterKeyPress}
            inputProps={{ style: { fontSize: "2.4rem", fontWeight: 800 } }}
            sx={{ width: "100%" }}
          />
          <IconButton sx={{ marginLeft: 1 }} onClick={handleEditModeToggle}>
            <CheckIcon fontSize="medium" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
