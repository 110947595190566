import axios from "axios";
import { useAuthContext } from "./Auth/useAuthContext";
import { useLogout } from "./Auth/useLogout";
import { useSnackbar } from "notistack";

export const useAxios = () => {
  const { user } = useAuthContext();
  const { logout } = useLogout();
  const { enqueueSnackbar } = useSnackbar();

  const Axios = async (endpoint, method, data = null) => {
    const baseUrl = process.env.REACT_APP_BASEURL;
    if (method === "GET") {
      try {
        const response = await axios.get(baseUrl + endpoint, {
          headers: { Authorization: `Bearer ${user.token}` },
        });

        if (response.status === 200) {
          return response;
        }
      } catch (error) {
        console.error("Fout bij het ophalen van gegevens:", error);

        enqueueSnackbar("Oops, er is iets fout gegaan", {
          variant: "error",
        });

        if (error.response && error.response.status === 401) {
          logout();
        }
      }
    }

    if (method === "DOWNLOAD") {
      try {
        const response = await axios.get(baseUrl + endpoint, {
          headers: { Authorization: `Bearer ${user.token}` },
          responseType: "blob",
        });

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", data);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);

          return response;
        }
      } catch (error) {
        console.error("Fout bij het ophalen van gegevens:", error);

        if (error.response && error.response.status === 401) {
          logout();
        }
      }
    }

    if (method === "POST") {
      try {
        const response = await axios.post(baseUrl + endpoint, data, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

        return response;
      } catch (error) {
        console.error("Fout bij het ophalen van gegevens:", error);

        if (error.response && error.response.status === 401) {
          logout();
        }
      }
    }

    if (method === "PUT") {
      try {
        const response = await axios.put(baseUrl + endpoint, data, {
          headers: { Authorization: `Bearer ${user.token}` },
        });

        if (response.status === 200) {
          return response.data;
        }
      } catch (error) {
        console.error("Fout bij het ophalen van gegevens:", error);

        if (error.response && error.response.status === 401) {
          logout();
        }
      }
    }

    if (method === "DELETE") {
      let url = baseUrl + endpoint;

      if (data) {
        url += `/${data}`;
      }

      try {
        const response = await axios.delete(url, {
          headers: { Authorization: `Bearer ${user.token}` },
          data: data,
        });

        if (response.status === 200) {
          return response.data;
        }
      } catch (error) {
        console.error("Fout bij het ophalen van gegevens:", error);

        if (error.response && error.response.status === 401) {
          logout();
        }
      }
    }
  };

  return { Axios };
};
