import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";

import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";

function AddEditDialog({
  openDialog = false,
  title,
  afterClose = () => {},
  width = "1200px",
  handleSave = () => {},
  loading = false,
  content,
  disableHandleSave,
  disableEnterToSave = false,
}) {
  const [open, setOpen] = useState(openDialog);

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    afterClose();
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: width, // Set your width here
            },
          },
          // zIndex: 10000,
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter" && !disableEnterToSave) {
            console.log("fre");
            event.preventDefault();
            handleSave();
          }
        }}
      >
        <DialogTitle sx={{ py: 3 }}>
          <Typography style={{ fontSize: "35px", fontWeight: 700 }}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Sluiten</Button>

          <Button
            variant="contained"
            onClick={() => handleSave()}
            disabled={loading || disableHandleSave}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <span>Opslaan</span>
              {loading && (
                <CircularProgress size="1rem" sx={{ marginLeft: 0.7 }} />
              )}
            </Box>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AddEditDialog;
