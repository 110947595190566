import { useAxios } from "../useAxios";
import { useAppDataContext } from "../AppData/useAppDataContext";
import { useSnackbar } from "notistack";

export const useFetchTableData = () => {
  const { Axios } = useAxios();
  const { dispatch } = useAppDataContext();
  const { enqueueSnackbar } = useSnackbar();

  const fetchTableData = async (
    endpoint,
    customAppDataName,
    returnInsteadOfDispatch = false,
  ) => {
    let appDataName = customAppDataName || endpoint?.split("/").pop();
    if (appDataName?.includes("?")) {
      appDataName = appDataName.split("?")[0];
    }
    const tableData = await Axios(endpoint, "GET");

    if (!tableData?.data) {
      enqueueSnackbar("Oops, er is iets fout gegaan", {
        variant: "error",
      });
      return;
    }

    if (!returnInsteadOfDispatch) {
      dispatch({
        type: "SET_APP_DATA",
        payload: { dataName: appDataName, data: tableData.data },
      });
    }

    return tableData.data;
  };

  return { fetchTableData };
};
