import { useRefresh } from "../Hooks/useRefresh";
import DataTable from "../Components/UI/DataTable";
import headers from "../Utils/Fields/websiteOverviewFields";
import PageContainer from "../Components/UI/PageContainer";
import endpoint from "../Utils/endpoint";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAppDataContext } from "../Hooks/AppData/useAppDataContext";
import { useSnackbar } from "notistack";

const WebsitesOverviewPage = () => {
  useRefresh();
  const navigate = useNavigate();
  const { appData } = useAppDataContext();
  const { enqueueSnackbar } = useSnackbar();

  // useEffect(() => {
  //   const lastUrl = sessionStorage.getItem("lastUrl");
  //   if (lastUrl) {
  //     navigate(lastUrl);
  //     sessionStorage.removeItem("lastUrl");
  //   } else {
  //     navigate("/");
  //   }
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (appData?.websites?.length === 0) {
      enqueueSnackbar(`Voeg een website toe om te beginnen`, {
        variant: "info",
      });
      navigate(`/websites`);
    } else if (appData?.websites?.length === 1) {
      navigate(`/website/${appData.websites[0]._id}`);
    } else {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [appData]);

  return (
    <PageContainer>
      <DataTable
        headers={headers}
        title="Website overzicht"
        endpoint={endpoint.websites}
        autoPageSize
        autoHeight
        allowEdit={false}
        allowDuplicate={false}
        allowDelete={false}
        onRowClick={(params) => {
          navigate(`website/${params.id}`);
        }}
      />
    </PageContainer>
  );
};

export default WebsitesOverviewPage;
