import * as React from "react";
import {
  DataGrid,
  nlNL,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { styled } from "@mui/system";
import CustomNoRowsOverlay from "./NoRows";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import { useAppDataContext } from "../../Hooks/AppData/useAppDataContext";
import { useFetchTableData } from "../../Hooks/AppData/useFetchTableData";
import RowActions from "../RowActions";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import formatToDutchDate from "../../Utils/FormatToDutchDate";
import TableActions from "./TableActions";
import ConfirmDeleteTableRowDialog from "../UI/ConfirmDeleteTableRowDialog";

const StyledDataGrid = styled(DataGrid)({
  width: "100%",
  overflowX: "hidden",
});

const DataTable = ({
  endpoint,
  headers,
  title,
  autoHeight = false,
  density = "standard",
  pageSize = 100,
  checkboxSelection = false,
  onEditCopyRow,
  singular,
  onRowClick = (params) => onEditCopyRow({ mode: "edit", row: params.row }),
  displayAddIcon = false,
  query,
  initialSort = { field: "defaultField", sort: "asc" },
  allowEdit = true,
  allowDuplicate = true,
  allowDelete = true,
  tableActions,
  customAppDataName = undefined,
  customData,
  allowTableActions = true,
  afterDelete = () => {},
  customRowActions = [],
  displayFilter = false,
  selectedRows = () => {},
  disableHeader = false,
}) => {
  const theme = useTheme();
  const { appData } = useAppDataContext();
  const { fetchTableData } = useFetchTableData();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const [appDataName, setAppDataName] = useState("");
  const [tableData, setTableData] = useState([]);

  const [sortModel, setSortModel] = useState([initialSort]);

  useEffect(() => {
    setSortModel([initialSort]);
    // eslint-disable-next-line
  }, [headers]);

  useEffect(() => {
    if (query && Object.keys(query).length !== 0) {
      const queryParams = Object.keys(query)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`,
        )
        .join("&");
      getTableData(`${endpoint}?${queryParams}`);
    } else {
      if (endpoint) {
        getTableData(endpoint);
      } else {
        console.log("Endpoint is missing");
      }
    }
    // eslint-disable-next-line
  }, [endpoint]);

  async function getTableData(endpoint) {
    await fetchTableData(endpoint, customAppDataName);
    setIsLoading(false);
  }

  useEffect(() => {
    setAppDataName(endpoint?.split("/").pop());
  }, [endpoint]);

  useEffect(() => {
    if (customAppDataName && appData && appData[customAppDataName]) {
      setTableData(appData[customAppDataName]);
    } else if (appData && appData[appDataName]) {
      setTableData(appData[appDataName]);
    } else if (customData) {
      setTableData(customData);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [appData, endpoint, customData]);

  const item = {
    field: "actions",
    headerName: "Acties",
    hideable: false,
    headerAlign: "center",
    width: 90,
    sortable: false,
    align: "center",
    renderCell: (params) => (
      <div className="action-button">
        <RowActions
          row={params.row}
          onEditCopyRow={onEditCopyRow}
          onDeleteRow={onDeleteRow}
          allowEdit={allowEdit}
          allowDuplicate={allowDuplicate}
          allowDelete={allowDelete}
          customRowActions={customRowActions}
        />
      </div>
    ),
  };

  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    keyProp: 0,
    show: false,
    id: "",
  });

  async function onDeleteRow(row) {
    setConfirmDeleteDialog((prevState) => ({
      ...prevState,
      show: true,
      keyProp: prevState.keyProp + 1,
      id: row._id,
    }));
  }

  async function afterConfirmDeleteClose() {
    setConfirmDeleteDialog((prevState) => ({
      ...prevState,
      show: false,
    }));
  }

  let headersWithMenuItems = [...headers];

  if (
    allowEdit ||
    allowDelete ||
    allowDuplicate ||
    customRowActions.length > 0
  ) {
    headersWithMenuItems.push(item);
  }

  const adjustedHeaders = headersWithMenuItems.map((header) => {
    if (header.label) {
      header.headerName = header.label;
    }

    if (!header.width) {
      header.flex = 1;
    }

    if (header.display === "textfield") {
      return {
        ...header,
      };
    }

    if (header.display === "autocomplete") {
      return {
        ...header,
      };
    }

    if (header.display === "date") {
      return {
        ...header,
        valueFormatter: (params) => formatToDutchDate(params.value),
      };
    }

    if (header.display === "currency") {
      return {
        ...header,

        type: "number",
        headerAlign: "left",
        align: "left",
        valueFormatter: (params) => {
          if (params.value === null || params.value === undefined) {
            return null;
          }
          const formattedValue = `€${params.value.toFixed(2)}`;
          return formattedValue.replace(".", ",");
        },
      };
    }

    if (header.display === "number") {
      return {
        ...header,

        headerAlign: "left",
        align: "left",
        cellClassName: header.editable ? "editable-cell" : "",
      };
    }

    return header;
  });

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});

  useEffect(() => {
    const newVisibilityModel = headers.reduce((model, header) => {
      model[header.field] = header.visible !== false;
      return model;
    }, {});

    setColumnVisibilityModel(newVisibilityModel);
  }, [headers]);

  function getRowId(row) {
    return row._id;
  }

  const mobileItem = {
    field: "tableData",
    headerName: "Tabelgegevens",
    flex: 1,
    renderCell: (params) => (
      <div style={{ width: "100%", marginBottom: "12px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 1,
          }}
        >
          <div className="action-button">
            <RowActions
              row={params.row}
              onEditCopyRow={onEditCopyRow}
              onDeleteRow={onDeleteRow}
            />
          </div>
        </Box>
        <div>
          {adjustedHeaders.map((header) => {
            const value = header.valueGetter
              ? header.valueGetter({ row: params.row })
              : params.row[header.field];

            const formattedValue = header.valueFormatter
              ? header.valueFormatter({ value, row: params.row })
              : value;

            return (
              <div key={header.field} style={{ display: "flex" }}>
                <div
                  style={{
                    flex: "1",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {header.label}
                </div>
                <div
                  style={{
                    flex: "1",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {formattedValue}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    ),
  };

  function CustomToolbar() {
    if (isLargeScreen) {
      return (
        <GridToolbarContainer
          style={{
            margin: "10px 10px 5px 10px",
            gap: 0,
          }}
        >
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <GridToolbarQuickFilter />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <h1 style={{ margin: "3px 0 3px 0" }}>{title}</h1>
              {displayAddIcon && (
                <IconButton
                  onClick={() =>
                    onEditCopyRow({
                      mode: "new",
                    })
                  }
                  size="large"
                >
                  <AddIcon />
                </IconButton>
              )}
            </Box>

            {allowTableActions && (
              <TableActions
                endpoint={endpoint}
                tableActions={tableActions}
                isLoading={(state) => setIsLoading(state)}
                customAppDataName={customAppDataName}
              />
            )}
          </Box>

          <div>
            {displayFilter && <GridToolbarFilterButton />}
            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
          </div>
        </GridToolbarContainer>
      );
    } else {
      return (
        <GridToolbarContainer
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 10px 4px 10px",
            position: "relative",
          }}
          sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "6px",
            }}
          >
            <h1 style={{ margin: "5px 0 5px 0", fontSize: "1.4rem" }}>
              {title}
            </h1>
            {displayAddIcon && (
              <IconButton
                onClick={() =>
                  onEditCopyRow({
                    mode: "new",
                  })
                }
                size="large"
              >
                <AddIcon />
              </IconButton>
            )}
          </div>
        </GridToolbarContainer>
      );
    }
  }

  const [isLoading, setIsLoading] = useState(true);

  function handleRowClick(params, event) {
    if (event.target.closest(".action-button")) {
      return;
    } else {
      if (onRowClick) {
        onRowClick(params);
      }
    }
  }

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  useEffect(() => {
    const rowObjects = rowSelectionModel.map((id) =>
      tableData.find((row) => row._id === id),
    );
    selectedRows(rowObjects);
  }, [rowSelectionModel]);

  return (
    <div>
      <StyledDataGrid
        onRowClick={handleRowClick}
        rows={tableData}
        autoHeight={autoHeight}
        columns={isLargeScreen ? adjustedHeaders : [mobileItem]}
        getRowId={getRowId}
        checkboxSelection={checkboxSelection}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        disableRowSelectionOnClick
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
        slots={{
          noRowsOverlay: () => <CustomNoRowsOverlay text={title} />,
          loadingOverlay: LinearProgress,
          toolbar: disableHeader ? null : CustomToolbar,
          // footer: CustomerFooter,
        }}
        loading={isLoading}
        sx={{
          "--DataGrid-overlayHeight": "300px",
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .editable-cell:focus-within": {
            outline: `1px solid ${theme.palette.primary.main}`,
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none",
          },
          ...(onRowClick && {
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }),
          ...(!isLargeScreen && {
            "& .MuiDataGrid-columnHeaders": {
              display: "none",
            },
          }),
        }}
        rowHeight={
          headers.find((item) => item.type === "image")
            ? headers.find((item) => item.type === "image").height || 100
            : undefined
        }
        getRowHeight={!isLargeScreen ? () => "auto" : undefined}
        initialState={{
          pagination: { paginationModel: { pageSize: pageSize } },
        }}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        density={density}
        disableColumnMenu
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
      />
      <ConfirmDeleteTableRowDialog
        width="800px"
        title={singular || title}
        openDialog={confirmDeleteDialog.show}
        uniqueKey={confirmDeleteDialog.keyProp}
        id={confirmDeleteDialog.id}
        endpoint={endpoint}
        afterClose={afterConfirmDeleteClose}
        customAppDataName={customAppDataName}
        afterDelete={afterDelete}
      />
    </div>
  );
};

export default DataTable;
