import GeneralAddEditDatatable from "../Components/UI/GeneralAddEditDatatable";
import headers from "../Utils/Fields/ignoreListFields";
import endpoint from "../Utils/endpoint";
import { useParams } from "react-router-dom";

const IgnoreListPage = () => {
  const { websiteId } = useParams();

  return (
    <GeneralAddEditDatatable
      headers={headers}
      title="Negeerlijst"
      singular="Product"
      endpoint={`${endpoint.ignoreList}/${websiteId}`}
      allowEdit={false}
      allowDuplicate={false}
      density="compact"
      initialSort={{ field: "productName", sort: "asc" }}
    />
  );
};

export default IgnoreListPage;
