import AddEditDialog from "./AddEditDialog";
import fields from "../../Utils/Fields/featureFields";
import endpoint from "../../Utils/endpoint";
import { useParams } from "react-router-dom";

const AddEditFeatureDialog = ({
  openDialog,
  afterClose = () => {},
  dialogMode,
  patchData,
  afterSave,
}) => {
  const { websiteId, articleId } = useParams();

  function transformData(data) {
    return { features: data };
  }

  return (
    <AddEditDialog
      openDialog={openDialog}
      title="Kenmerken"
      singular="Kenmerk"
      fields={fields}
      afterClose={afterClose}
      endpoint={`${endpoint.features}/website/${websiteId}/article/${articleId}`}
      dialogMode={dialogMode}
      patchData={patchData}
      afterSave={afterSave}
      transformData={transformData}
      customAppDataName="features"
    />
  );
};

export default AddEditFeatureDialog;
