import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import endpoint from "../../Utils/endpoint";
import { useAxios } from "../../Hooks/useAxios";
import { useParams } from "react-router-dom";

export default function ControlledAccordions({ content, refreshArticle }) {
  const [expanded, setExpanded] = React.useState(false);
  const { websiteId, articleId } = useParams();
  const { Axios } = useAxios();
  const [loading, setLoading] = useState({});

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  async function handleDelete(id) {
    setLoading({ [id]: true });
    await Axios(
      `${endpoint.contentArticle}/website/${websiteId}/article/${articleId}/productContent/${id}`,
      "DELETE",
    );
    refreshArticle();
    setTimeout(() => {
      setLoading({ id: false });
    }, 2000);
  }

  return (
    <div>
      {content &&
        content.map((row, index) => {
          return (
            <Accordion
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              id={index}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ alignItems: "center" }}
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  {row.title}
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  {row.subTitle}
                </Typography>
                {row.content.__html && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      width: "100%",
                      paddingRight: 2,
                    }}
                  >
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDelete(row._id);
                      }}
                      disabled={loading[row._id]}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
              </AccordionSummary>
              <AccordionDetails>
                {row.content.__html ? (
                  <Box>
                    <Typography
                      dangerouslySetInnerHTML={row.content}
                    ></Typography>
                  </Box>
                ) : (
                  <Typography>{row.content}</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
    </div>
  );
}
