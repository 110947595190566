import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useAxios } from "../Hooks/useAxios";
import endpoint from "../Utils/endpoint";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useAppDataContext } from "../Hooks/AppData/useAppDataContext";
import { useFetchTableData } from "../Hooks/AppData/useFetchTableData";

const ScrapeContent = ({ params, article, refreshArticle }) => {
  const { Axios } = useAxios();
  const { fetchTableData } = useFetchTableData();
  const { appData } = useAppDataContext();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { websiteId, articleId } = useParams();
  const [alreadyScraped, setAlreadyScraped] = useState(false);
  const [supportedDescriptionDomains, setSupportedDescriptionDomains] =
    useState({});

  useEffect(() => {
    async function getSupported() {
      const domainsWithDescriptions = await fetchTableData(
        `${endpoint.contentArticle}/supportedDescriptionDomains`,
        undefined,
        true,
      );
      setSupportedDescriptionDomains(domainsWithDescriptions);
    }
    getSupported();
  }, [appData]);

  const handleClick = async (event) => {
    event.stopPropagation();
    setLoading(true);
    const response = await Axios(
      `${endpoint.contentArticle}/website/${websiteId}/article/${articleId}/content`,
      "POST",
      {
        url: params.row.url,
      },
    );

    if (response?.status === 200) {
      enqueueSnackbar(
        `Content van ${params.row.retailerName} succesvol opgehaald`,
        { variant: "success" },
      );
      await refreshArticle();
      setLoading(false);
      setAlreadyScraped(true);
    } else {
      enqueueSnackbar("Er ging iets mis met het ophalen van de content", {
        variant: "error",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    const retailerNames = article?.productContent?.map(
      (retailer) => retailer.title,
    );
    const present = retailerNames?.includes(params.row.retailerName);
    setAlreadyScraped(present);
  }, [article]);

  return (
    <IconButton
      onClick={handleClick}
      disabled={
        !supportedDescriptionDomains.hasOwnProperty(params.row.domain) ||
        loading ||
        alreadyScraped
      }
    >
      {loading ? (
        <CircularProgress size="1.5rem" />
      ) : (
        <ShoppingCartIcon
          style={{ color: alreadyScraped ? "lightGreen" : "" }}
        />
      )}
    </IconButton>
  );
};

export default ScrapeContent;
