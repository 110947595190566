import { useState } from "react";
import DataTable from "./UI/DataTable";
import { DialogModes } from "../Utils/DialogModes";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import endpoint from "../Utils/endpoint";
import AddEditFeatureDialog from "./Dialogs/AddEditFeatureDialog";
import { styled } from "@mui/system";

const CustomCellNoWrap = styled("div")({
  whiteSpace: "normal",
  wordWrap: "break-word",
});

const ProductFeatures = ({ refreshArticle }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { websiteId, articleId } = useParams();

  const fields = [
    {
      field: "description",
      label: "Kenmerk",
      col: 12,
      display: "textfield",
      rows: 3,
      required: true,
      renderCell: (params) => (
        <CustomCellNoWrap>{params.value}</CustomCellNoWrap>
      ),
    },
  ];

  function handleClose() {
    setOpenDialog(false);
  }

  const [patchCopyData, setPatchCopyData] = useState({});

  const handleEditCopy = (rowData) => {
    setPatchCopyData(rowData);
    setOpenDialog(true);
  };

  return (
    <Box>
      <DataTable
        headers={fields}
        title="Product kenmerken"
        singular="Kenmerk"
        autoPageSize
        autoHeight
        onEditCopyRow={handleEditCopy}
        displayAddIcon
        allowDuplicate={false}
        endpoint={`${endpoint.features}/website/${websiteId}/article/${articleId}`}
        density="compact"
        customAppDataName="features"
        afterDelete={() => refreshArticle()}
      />
      <AddEditFeatureDialog
        openDialog={openDialog}
        afterClose={handleClose}
        patchData={patchCopyData.row}
        dialogMode={DialogModes[patchCopyData.mode]}
        afterSave={() => refreshArticle()}
      />
    </Box>
  );
};

export default ProductFeatures;
