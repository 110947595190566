const endpoint = {
  websites: "/api/user/websites",
  articles: "/api/user/articles",
  contentArticle: "/api/user/content",
  sellers: "/api/sellers",
  affiliates: "/api/user/affiliates",
  specifications: "/api/user/specifications",
  features: "/api/user/features",
  ai: "/api/user/ai",
  category: "/api/user/categories",
  missingOffers: "/api/user/missingOffers",
  monitoredCategories: "/api/user/monitoredCategories",
  ignoreList: "/api/user/ignoreList",
  linkedOfferList: "/api/user/linkedOfferList",
};

export default endpoint;
