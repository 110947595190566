import LinkButton from "../../Components/UI/LinkButton";

const fields = [
  {
    field: "productName",
    label: "Productnaam",
    width: 300,
  },
  {
    field: "category",
    label: "Categorie",
  },
  {
    field: "retailer",
    label: "Winkel",
  },
  {
    field: "url",
    label: "Url",
    width: 75,
    renderCell: (params) => <LinkButton value={params.value} />,
  },
  {
    field: "articleName",
    label: "Gekoppeld artikel",
    width: 300,
  },
  {
    field: "articleId",
    label: "Ga naar",
    width: 75,
    renderCell: (params) => (
      <LinkButton
        navigateTo={`/website/${params.row.websiteId}/article/${params.row.articleId}`}
      />
    ),
  },
];

export default fields;
