import * as React from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";

export default function TextArea({ placeholder, textAreaContent, value }) {
  const theme = useTheme();
  const [content, setContent] = React.useState(value);

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  useEffect(() => {
    setContent(value);
  }, [value]);

  useEffect(() => {
    console.log(value);
  }, [value]);

  useEffect(() => {
    textAreaContent(content);
  }, [content]);

  return (
    <TextareaAutosize
      placeholder={placeholder}
      style={{
        width: "100%",
        height: "100%",
        fontSize: theme.typography.body1.fontSize,
        fontFamily: "Roboto",
        lineHeight: "1.5rem",
      }}
      value={content}
      onChange={handleContentChange}
    />
  );
}
