import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {AppDataContextProvider} from "./Hooks/AppData/AppDataContext";
import {AuthContextProvider} from "./Hooks/Auth/AuthContext";
import SnackbarProviderWrapper from "./Hooks/useSnackbar";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthContextProvider>
        <AppDataContextProvider>
            <SnackbarProviderWrapper> <App /></SnackbarProviderWrapper>

        </AppDataContextProvider>
    </AuthContextProvider>,
);
