import PageContainer from "../Components/UI/PageContainer";
import ImageUploadBlock from "../Components/ImageUploadBlock";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import { useAppDataContext } from "../Hooks/AppData/useAppDataContext";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import FullScreenLoader from "../Components/UI/FullScreenLoader";
import CategoryBreadCrumbs from "../Components/CategoryBreadCrumbs";

import PublishButton from "../Components/PublishButton";
import endpoint from "../Utils/endpoint";

import MetaDetails from "../Components/MetaDetails";

import Affiliates from "../Components/Affiliates";
import ArticleTitle from "../Components/ArticleTitle";
import { useAxios } from "../Hooks/useAxios";
import ProductSpecifications from "../Components/ProductSpecifications";
import ProductFeatures from "../Components/ProductFeatures";
import LongProductDescription from "../Components/LongProductDescription";
import ContentAiButtons from "../Components/ContentAiButtons";
import { PuffLoader } from "react-spinners";

const ArticleEditPage = () => {
  const { websiteId, articleId } = useParams();
  const { appData } = useAppDataContext();
  const [loading, setLoading] = useState(false);

  const { Axios } = useAxios();
  const [article, setArticle] = useState({});

  async function getArticleData() {
    const article = await Axios(
      `${endpoint.articles}/website/${websiteId}/article/${articleId}`,
      "GET",
    );

    const website = appData?.websites?.find((site) => site._id === websiteId);
    setArticle({
      ...article.data[0],
      websiteName: website?.websiteName,
    });
  }

  useEffect(() => {
    getArticleData();
    // eslint-disable-next-line
  }, []);

  async function refreshArticle() {
    await getArticleData();
  }

  useEffect(() => {
    console.log(article);
  }, [article]);

  const [showFullScreenOverlayLoader, setShowFullScreenOverlayLoader] =
    useState({ show: true, transparant: false });

  function displayLoader(state) {
    if (state) {
      setShowFullScreenOverlayLoader({ show: true, transparant: true });
    } else {
      setShowFullScreenOverlayLoader({ show: false, transparant: true });
    }
  }

  useEffect(() => {
    if (article) {
      const timer = setTimeout(() => {
        setShowFullScreenOverlayLoader({ show: false, transparant: false });
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [article]);

  return (
    <PageContainer width="xl">
      <Box>
        <Grid container spacing={2}>
          <Grid item md={6} lg={6}>
            <ArticleTitle article={article} refreshArticle={refreshArticle} />

            <CategoryBreadCrumbs
              article={article}
              refreshArticle={refreshArticle}
            />
          </Grid>
          <Grid item md={6} lg={6}>
            <PublishButton
              article={article}
              displayLoader={(state) => setLoading(state)}
              refreshArticle={refreshArticle}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ImageUploadBlock
              refreshArticle={refreshArticle}
              displayLoader={displayLoader}
              article={article}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ paddingTop: "6px" }}>
              <ContentAiButtons
                article={article}
                articleId={articleId}
                refreshArticle={refreshArticle}
                displayLoader={displayLoader}
              />
              <MetaDetails article={article} refreshArticle={refreshArticle} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Affiliates article={article} refreshArticle={refreshArticle} />
          </Grid>
          <Grid item xs={12}>
            <ProductSpecifications refreshArticle={refreshArticle} />
          </Grid>
          <Grid item xs={12}>
            <ProductFeatures refreshArticle={refreshArticle} />
          </Grid>
          <Grid item xs={12} md={12}>
            <LongProductDescription
              refreshArticle={refreshArticle}
              article={article}
            />
          </Grid>
        </Grid>
        <FullScreenLoader loading={loading} />
        {showFullScreenOverlayLoader.show && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor:
                showFullScreenOverlayLoader.show &&
                showFullScreenOverlayLoader.transparant
                  ? "rgba(255, 255, 255, 0.9)"
                  : "white",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "20000",
            }}
          >
            <PuffLoader color="#ff5733" size={100} />
          </div>
        )}
      </Box>
    </PageContainer>
  );
};

export default ArticleEditPage;
