import Box from "@mui/material/Box";
import articleKeys from "../Utils/articleKeys";

import ShortDescriptionList from "./ShortDescriptionList";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import AiDataTable from "./AiDataTable";
import AiCard from "./AiCard";
import CustomAccordion from "./CustomAccordion";
import Typography from "@mui/material/Typography";
import * as React from "react";
import aiMessageOptions from "../Utils/aiMessageOptions";
import endpoint from "../Utils/endpoint";
import { useParams } from "react-router-dom";
import { useAxios } from "../Hooks/useAxios";
import { useSnackbar } from "notistack";
import { useFetchTableData } from "../Hooks/AppData/useFetchTableData";

const AiDialogContent = forwardRef(
  (
    {
      close,
      displayLoader,
      setDisableSave,
      initialAiResponse,
      loadingAccordion,
      refreshArticle,
    },
    ref,
  ) => {
    const { websiteId, articleId } = useParams();
    const { Axios } = useAxios();
    const { enqueueSnackbar } = useSnackbar();
    const { fetchTableData } = useFetchTableData();

    useEffect(() => {
      setAiContent(initialAiResponse);
    }, [initialAiResponse]);

    useImperativeHandle(ref, () => ({
      async handleSave() {
        displayLoader(true);
        let postObject = {};

        const keys = Object.keys(aiContent);

        keys.forEach((key) => {
          if (
            key === articleKeys.productDescription ||
            key === articleKeys.metaDescription
          ) {
            const selectedIndex = selectedObjects[key];
            postObject[key] = aiContent[key][selectedIndex];
          } else if (key === articleKeys.specifications) {
            // do nothing
          } else {
            postObject[key] = aiContent[key];
          }
        });

        const response = await Axios(
          `${endpoint.contentArticle}/website/${websiteId}/article/${articleId}`,
          "POST",
          postObject,
        );

        if (response && response.status === 200) {
          await refreshArticle();
          await fetchTableData(
            `${endpoint.specifications}/website/${websiteId}/article/${articleId}`,
            "specifications",
          );
          await fetchTableData(
            `${endpoint.features}/website/${websiteId}/article/${articleId}`,
            "features",
          );

          setTimeout(() => {
            displayLoader(false);
            enqueueSnackbar("Artikel succesvol bijgewerkt!", {
              variant: "success",
            });
          }, 1000);
        } else {
          enqueueSnackbar(
            "Er ging iets mis met het bijwerken van het artikel!",
            {
              variant: "error",
            },
          );
        }
      },
    }));

    const [aiContent, setAiContent] = useState(initialAiResponse);
    const [selectedObjects, setSelectedObjects] = useState({
      [articleKeys.productDescription]: null,
      [articleKeys.metaDescription]: null,
    });

    useEffect(() => {
      if (
        aiContent[articleKeys.productDescription] &&
        selectedObjects[articleKeys.productDescription] === null
      ) {
        setDisableSave(true);
      } else if (
        aiContent[articleKeys.metaDescription] &&
        selectedObjects[articleKeys.metaDescription] === null
      ) {
        setDisableSave(true);
      } else {
        setDisableSave(false);
      }
    }, [aiContent, selectedObjects]);

    function updateAiContent(key, index) {
      setSelectedObjects({ ...selectedObjects, [key]: index });
    }

    function deleteKey(key) {
      const updatedObject = { ...aiContent };
      delete updatedObject[key];
      setAiContent(updatedObject);

      if (Object.keys(updatedObject).length === 0) {
        close();
      }
      setExpandedAccordion(null);
    }

    async function requestRevision(key, message) {
      if (!message) return;

      const rememberWhichToExpaned = expandedAccordion;

      setExpandedAccordion(null);
      displayLoader(true);

      let response;

      if (message === "refresh") {
        response = await Axios(
          `${endpoint.ai}/website/${websiteId}/article/${articleId}/ai/refresh`,
          "POST",
          { [key]: aiContent[key] },
        );
      } else {
        response = await Axios(
          `${endpoint.ai}/website/${websiteId}/article/${articleId}/ai/improve`,
          "POST",
          { [key]: aiContent[key], message },
        );
      }

      if (response && response.status === 200) {
        setExpandedAccordion(rememberWhichToExpaned);
        setAiContent((prevState) => ({
          ...prevState,
          [key]: response.data[key],
        }));
      } else {
        enqueueSnackbar("Er ging iets mis met het genereren van de content", {
          variant: "error",
        });
      }

      displayLoader(false);
    }

    useEffect(() => {
      console.log(aiContent);
    }, [aiContent]);

    const [expandedAccordion, setExpandedAccordion] = useState(null);

    function updateExpandedKey(key) {
      setExpandedAccordion(key);
    }

    return (
      <Box>
        <CustomAccordion
          title="Korte beschrijving"
          subTitle="Kies de gewenste beschrijving voor dit product"
          disabled={
            !aiContent[articleKeys.productDescription] ||
            loadingAccordion[aiMessageOptions.description]
          }
          id={articleKeys.productDescription}
          deleteKey={deleteKey}
          updateExpandedKey={updateExpandedKey}
          expand={expandedAccordion}
          loading={loadingAccordion[aiMessageOptions.description]}
        >
          {aiContent[articleKeys.productDescription] && (
            <ShortDescriptionList
              content={aiContent}
              updateAiContent={updateAiContent}
              deleteKey={deleteKey}
              objectKey={articleKeys.productDescription}
              requestRevision={requestRevision}
            />
          )}
        </CustomAccordion>

        <CustomAccordion
          title="Meta beschrijving"
          subTitle="Kies de gewenste meta beschrijving voor dit product"
          disabled={
            !aiContent[articleKeys.metaDescription] ||
            loadingAccordion[aiMessageOptions.description]
          }
          id={articleKeys.metaDescription}
          deleteKey={deleteKey}
          updateExpandedKey={updateExpandedKey}
          expand={expandedAccordion}
          loading={loadingAccordion[aiMessageOptions.description]}
        >
          {aiContent[articleKeys.metaDescription] && (
            <ShortDescriptionList
              content={aiContent}
              updateAiContent={updateAiContent}
              objectKey={articleKeys.metaDescription}
              requestRevision={requestRevision}
            />
          )}
        </CustomAccordion>

        {/*<CustomAccordion*/}
        {/*  title="Specificaties"*/}
        {/*  subTitle="Dit zijn de genereerde specificaties"*/}
        {/*  disabled={*/}
        {/*    !aiContent[articleKeys.specifications] ||*/}
        {/*    loadingAccordion[aiMessageOptions.featuresAndSpecifications]*/}
        {/*  }*/}
        {/*  id={articleKeys.specifications}*/}
        {/*  deleteKey={deleteKey}*/}
        {/*  updateExpandedKey={updateExpandedKey}*/}
        {/*  expand={expandedAccordion}*/}
        {/*  loading={loadingAccordion[aiMessageOptions.featuresAndSpecifications]}*/}
        {/*>*/}
        {/*  {aiContent[articleKeys.specifications] && (*/}
        {/*    <AiDataTable*/}
        {/*      content={aiContent}*/}
        {/*      objectKey={articleKeys.specifications}*/}
        {/*      headers={[*/}
        {/*        { field: "key", label: "Type" },*/}
        {/*        { field: "value", label: "Omschrijving" },*/}
        {/*      ]}*/}
        {/*      requestRevision={requestRevision}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</CustomAccordion>*/}

        <CustomAccordion
          title="Product kenmerken"
          subTitle="Dit zijn de genereerde product kenmerken"
          disabled={
            !aiContent[articleKeys.features] ||
            loadingAccordion[aiMessageOptions.featuresAndSpecifications]
          }
          id={articleKeys.features}
          deleteKey={deleteKey}
          updateExpandedKey={updateExpandedKey}
          expand={expandedAccordion}
          loading={loadingAccordion[aiMessageOptions.featuresAndSpecifications]}
        >
          {aiContent[articleKeys.features] && (
            <AiDataTable
              content={aiContent}
              objectKey={articleKeys.features}
              headers={[{ field: "description", label: "Omschrijving" }]}
              requestRevision={requestRevision}
            />
          )}
        </CustomAccordion>

        <CustomAccordion
          title="Product beschrijving"
          subTitle="Dit is de product beschrijving"
          disabled={
            !aiContent[articleKeys.longProductDescription] ||
            loadingAccordion[aiMessageOptions.longProductDescription]
          }
          id={articleKeys.longProductDescription}
          deleteKey={deleteKey}
          updateExpandedKey={updateExpandedKey}
          expand={expandedAccordion}
          loading={loadingAccordion[aiMessageOptions.longProductDescription]}
        >
          {aiContent[articleKeys.longProductDescription] && (
            <AiCard
              objectKey={articleKeys.longProductDescription}
              requestRevision={requestRevision}
            >
              <Typography
                dangerouslySetInnerHTML={{
                  __html: aiContent[articleKeys.longProductDescription],
                }}
              ></Typography>
            </AiCard>
          )}
        </CustomAccordion>
      </Box>
    );
  },
);

export default AiDialogContent;
