const fields = [
  {
    field: "key",
    label: "Type",
    col: 12,
    display: "textfield",
    required: true,
  },
  {
    field: "value",
    label: "Omschrijving",
    col: 12,
    display: "textfield",
  },
];

export default fields;
