import { useEffect, useState } from "react";
import { useRefresh } from "../Hooks/useRefresh";
import DataTable from "../Components/UI/DataTable";
import headers from "../Utils/Fields/articleOverviewFields";
import { DialogModes } from "../Utils/DialogModes";
import PageContainer from "../Components/UI/PageContainer";
import endpoint from "../Utils/endpoint";
import AddEditArticleDialog from "../Components/Dialogs/AddEditArticleDialog";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchTableData } from "../Hooks/AppData/useFetchTableData";
import { useAppDataContext } from "../Hooks/AppData/useAppDataContext";

const ArticleOverviewPage = () => {
  useRefresh();
  const navigate = useNavigate();
  const { websiteId } = useParams();
  const { fetchTableData } = useFetchTableData();
  const { appData } = useAppDataContext();

  useEffect(() => {
    const customAppDataName = `categories-${websiteId}`;
    fetchTableData(`${endpoint.category}/${websiteId}`, customAppDataName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openDialog, setOpenDialog] = useState(false);
  const [articleOverviewDatatableName, setDatatableName] =
    useState("Artikel overzicht");

  useEffect(() => {
    const website = appData.websites.find(
      (website) => website._id === websiteId,
    );
    setDatatableName(`${website.websiteName} artikelen`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClose() {
    setOpenDialog(false);
  }

  const [patchCopyData, setPatchCopyData] = useState({});

  const handleEditCopy = (rowData) => {
    setPatchCopyData(rowData);
    setOpenDialog(true);
  };

  const [customHeaders, setCustomHeaders] = useState([]);

  useEffect(() => {
    if (appData) {
      const updatedHeaders = headers.map((header) => {
        if (
          appData[`categories-${websiteId}`] &&
          header.field === "headCategory"
        ) {
          return {
            ...header,
            renderCell: (params) => {
              return appData[`categories-${websiteId}`].find(
                (category) => category._id.toString() === params.value,
              )["categoryDisplayName"];
            },
          };
        }
        return header;
      });
      setCustomHeaders(updatedHeaders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData]);

  return (
    <PageContainer>
      <DataTable
        headers={customHeaders}
        title={articleOverviewDatatableName}
        singular="Artikel"
        endpoint={`${endpoint.articles}/website/${websiteId}`}
        autoPageSize
        autoHeight
        onEditCopyRow={handleEditCopy}
        displayAddIcon
        onRowClick={(params) => {
          navigate(`/website/${websiteId}/article/${params.id}`);
        }}
        allowDuplicate={false}
        density="compact"
      />
      <AddEditArticleDialog
        openDialog={openDialog}
        afterClose={handleClose}
        patchData={patchCopyData.row}
        dialogMode={DialogModes[patchCopyData.mode]}
      />
    </PageContainer>
  );
};

export default ArticleOverviewPage;
