import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import _ from "lodash";

const RowActions = ({
  row,
  onEditCopyRow,
  onDeleteRow,
  allowEdit,
  allowDuplicate,
  allowDelete,
  customRowActions,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const getMenuItems = (allowEdit, allowDuplicate, allowDelete) => {
    let items = [];

    if (allowEdit) {
      items.push({ label: "Bewerken", icon: "EditIcon" });
    }

    if (allowDuplicate) {
      items.push({ label: "Dupliceren", icon: "ContentCopyIcon" });
    }

    if (allowDelete) {
      items.push({ label: "Verwijderen", icon: "DeleteIcon" });
    }

    return items;
  };

  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const defaultMenuItems = getMenuItems(
      allowEdit,
      allowDuplicate,
      allowDelete,
    );

    if (customRowActions) {
      const updatedCustomRowActions = _.map(customRowActions, (action) => ({
        ...action,
        custom: true,
      }));

      setMenuItems(_.concat(updatedCustomRowActions, defaultMenuItems));
    } else {
      setMenuItems(defaultMenuItems);
    }
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getIconComponent = (iconName) => {
    switch (iconName) {
      case "EditIcon":
        return <EditIcon fontSize="medium" />;
      case "DeleteIcon":
        return <DeleteIcon fontSize="medium" />;
      case "ContentCopyIcon":
        return <ContentCopyIcon fontSize="medium" />;
      case "LocalGroceryStore":
        return <LocalGroceryStoreIcon fontSize="medium" />;
      default:
        return null;
    }
  };

  function handleMenuItemClick(label, row) {
    if (label === "Bewerken") {
      onEditCopyRow({
        row: row,
        mode: "edit",
      });
    }
    if (label === "Dupliceren") {
      onEditCopyRow({
        row: row,
        mode: "copy",
      });
    }
    if (label === "Verwijderen") {
      onDeleteRow(row);
    }

    handleMenuClose();
  }

  return (
    <div>
      {menuItems.length <= 2 ? (
        <div>
          {menuItems.map((item, index) => {
            if (!item.custom) {
              return (
                <IconButton
                  key={index}
                  onClick={() => {
                    handleMenuItemClick(item.label, row);
                  }}
                  style={{ margin: "0 -5px" }}
                >
                  {getIconComponent(item.icon)}
                </IconButton>
              );
            } else {
              return (
                <IconButton
                  key={index}
                  onClick={() => {
                    item.onClick(row);
                    handleMenuClose();
                  }}
                  style={{ margin: "0 -5px" }}
                >
                  {item.icon}
                </IconButton>
              );
            }
          })}
        </div>
      ) : (
        <div>
          <IconButton onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {menuItems.map((item, index) => {
              if (!item.custom) {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleMenuItemClick(item.label, row);
                    }}
                  >
                    {item.icon && (
                      <ListItemIcon>{getIconComponent(item.icon)}</ListItemIcon>
                    )}
                    {item.label}
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      item.onClick(row);
                      handleMenuClose();
                    }}
                  >
                    {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                    {item.label}
                  </MenuItem>
                );
              }
            })}
          </Menu>
        </div>
      )}
    </div>
  );
};

export default RowActions;
