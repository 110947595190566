import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import { useAxios } from "../../Hooks/useAxios";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useFetchTableData } from "../../Hooks/AppData/useFetchTableData";

const ConfirmDeleteTableRowDialog = ({
  width = "1200px",
  title,
  openDialog = false,
  uniqueKey,
  id,
  endpoint,
  afterClose,
  customAppDataName,
  afterDelete,
}) => {
  const [open, setOpen] = useState(openDialog);
  const { Axios } = useAxios();
  const { fetchTableData } = useFetchTableData();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleSave = async () => {
    setLoading(true);
    await Axios(endpoint, "DELETE", id);
    const response = await fetchTableData(endpoint, customAppDataName);

    setOpen(false);
    enqueueSnackbar(`${title} succesvol verwijderd`, {
      variant: "success",
    });
    setOpen(false);
    afterClose();
    afterDelete();
    setLoading(false);
  };

  return (
    <ConfirmDeleteDialog
      width={width}
      title={title}
      openDialog={open}
      key={uniqueKey}
      endpoint={endpoint}
      handleSave={handleSave}
      afterClose={afterClose}
      loading={loading}
    />
  );
};

export default ConfirmDeleteTableRowDialog;
