import FullScreenDialog from "./Dialogs/FullScreenDialog";
import TextArea from "./UI/TextArea";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import CustomAccordion from "./UI/Accordion";
import endpoint from "../Utils/endpoint";
import { useAxios } from "../Hooks/useAxios";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

const ArticleContentFullScreenDialog = ({
  openDialog,
  afterClose,
  article,
  articleId,
  refreshArticle,
}) => {
  const { Axios } = useAxios();
  const [textAreaContent, setTextAreaContent] = useState();
  const [transformedArticleContent, setTransformArticleContent] = useState([]);
  const { websiteId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [userInputId, setUserInputId] = useState();

  function updateTextAreaContent(content) {
    setTextAreaContent(content);
  }

  useEffect(() => {
    const userInput = article.productContent?.find(
      (content) => content?.title === "Productgegevens toevoegen",
    );
    if (userInput) {
      setTextAreaContent(userInput.content);
      setUserInputId(userInput._id);
    }
  }, [article]);

  useEffect(() => {
    const initialContent = article.productContent || [];

    const updatedProductContent = initialContent
      ?.map((item) => {
        if (item.title === "Productgegevens toevoegen") {
          return undefined;
        } else {
          return {
            ...item,
            content: { __html: item.content },
          };
        }
      })
      .filter(Boolean);

    const newProductContent = {
      title: "Productgegevens toevoegen",
      content: ownContent,
    };

    setTransformArticleContent([...updatedProductContent, newProductContent]);
  }, [article, textAreaContent]);

  async function afterSave() {
    const response = await Axios(
      `${endpoint.contentArticle}/website/${websiteId}/article/${articleId}/`,
      "POST",
      {
        productContent: {
          title: "Productgegevens toevoegen",
          content: textAreaContent,
          _id: userInputId,
        },
      },
    );

    if (response.status === 200) {
      enqueueSnackbar("Content succesvol opgeslagen", { variant: "success" });
      refreshArticle();
    } else {
      enqueueSnackbar("Er ging iets mis met het opslaan", { variant: "error" });
    }
  }

  const ownContent = (
    <Box sx={{ height: "500px" }}>
      <TextArea
        placeholder="Voeg hier alle productgegevens toe..."
        textAreaContent={updateTextAreaContent}
        value={textAreaContent}
        style={{ height: "600px" }}
      />
    </Box>
  );

  const content = (
    <Paper sx={{ margin: 4 }} elevation={3}>
      <CustomAccordion
        content={transformedArticleContent}
        refreshArticle={refreshArticle}
      />
    </Paper>
  );

  return (
    <FullScreenDialog
      openDialog={openDialog}
      afterClose={afterClose}
      afterSave={afterSave}
      content={content}
      dialogTitle="Productgegevens"
    />
  );
};

export default ArticleContentFullScreenDialog;
