import LinkButton from "../../Components/UI/LinkButton";

const fields = [
  {
    field: "category",
    required: true,
    label: "Categorie",
    col: 12,
    display: "textfield",
    filterOperators: "=",
  },
  {
    field: "babypark",
    label: "Baby Park",
    col: 12,
    display: "textfield",
    width: 100,
    renderCell: (params) => {
      if (params.value) {
        return <LinkButton value={params.value} />;
      }
    },
  },
  {
    field: "prenatal",
    label: "Prenatal",
    value: "",
    col: 12,
    display: "textfield",
    width: 100,
    renderCell: (params) => {
      if (params.value) {
        return <LinkButton value={params.value} />;
      }
    },
  },
  {
    field: "babydump",
    label: "BabyDump",
    col: 12,
    display: "textfield",
    width: 100,
    renderCell: (params) => {
      if (params.value) {
        return <LinkButton value={params.value} />;
      }
    },
  },
  {
    field: "vanastenbabysuperstore",
    label: "Van Asten Babysuperstore",
    value: "",
    col: 12,
    display: "textfield",
    width: 100,
    renderCell: (params) => {
      if (params.value) {
        return <LinkButton value={params.value} />;
      }
    },
  },
  {
    field: "babyentiener",
    label: "Baby en Tiener",
    value: "",
    col: 12,
    display: "textfield",
    width: 100,
    renderCell: (params) => {
      if (params.value) {
        return <LinkButton value={params.value} />;
      }
    },
  },
  {
    field: "babylux",
    label: "BabyLux",
    value: "",
    col: 12,
    display: "textfield",
    width: 100,
    renderCell: (params) => {
      if (params.value) {
        return <LinkButton value={params.value} />;
      }
    },
  },
  {
    field: "cybex",
    label: "Cybex",
    value: "",
    col: 12,
    display: "textfield",
    width: 100,
    renderCell: (params) => {
      if (params.value) {
        return <LinkButton value={params.value} />;
      }
    },
  },
  {
    field: "babyPlanet",
    label: "Babyplanet",
    value: "",
    col: 12,
    display: "textfield",
    width: 100,
    renderCell: (params) => {
      if (params.value) {
        return <LinkButton value={params.value} />;
      }
    },
  },
  {
    field: "maxiCosi",
    label: "Maxi-Cosi",
    value: "",
    col: 12,
    display: "textfield",
    width: 100,
    renderCell: (params) => {
      if (params.value) {
        return <LinkButton value={params.value} />;
      }
    },
  },
  // {
  //   field: "mamaLoes",
  //   label: "Mama Loes",
  //   value: "",
  //   col: 12,
  //   display: "textfield",
  //   width: 100,
  //   renderCell: (params) => {
  //     if (params.value) {
  //       return <LinkButton value={params.value} />;
  //     }
  //   },
  // },
  {
    field: "babyPlus",
    label: "Babyplus",
    value: "",
    col: 12,
    display: "textfield",
    width: 100,
    renderCell: (params) => {
      if (params.value) {
        return <LinkButton value={params.value} />;
      }
    },
  },
  {
    field: "bol",
    label: "Bol.com",
    value: "",
    col: 12,
    display: "textfield",
    width: 100,
    renderCell: (params) => {
      if (params.value) {
        return <LinkButton value={params.value} />;
      }
    },
  },
];

export default fields;
