import DataTable from "./UI/DataTable";
import AiCard from "./AiCard";

const AiDataTable = ({
  title,
  subTitle,
  deleteKey,
  objectKey,
  headers,
  content,
  requestRevision,
}) => {
  return (
    <AiCard
      title={title}
      subTitle={subTitle}
      deleteKey={deleteKey}
      objectKey={objectKey}
      requestRevision={requestRevision}
    >
      <DataTable
        headers={headers}
        title={title}
        customData={content[objectKey]}
        density="compact"
        allowDuplicate={false}
        allowEdit={false}
        allowDelete={false}
        onRowClick={() => {}}
        allowTableActions={false}
      />
    </AiCard>
  );
};

export default AiDataTable;
