import DataTable from "../Components/UI/DataTable";
import headers from "../Utils/Fields/websiteOverviewFields";
import PageContainer from "../Components/UI/PageContainer";
import endpoint from "../Utils/endpoint";
import { useEffect } from "react";
import { useAppDataContext } from "../Hooks/AppData/useAppDataContext";
import { useNavigate } from "react-router-dom";

const WebsiteCategoriesOverview = () => {
  const navigate = useNavigate();
  const { appData } = useAppDataContext();

  useEffect(() => {
    if (appData?.websites?.length === 1) {
      navigate(`/website/${appData.websites[0]._id}/categories`);
    } else {
      navigate("/website-categories-overview");
    }
    // eslint-disable-next-line
  }, [appData]);

  return (
    <PageContainer>
      <DataTable
        headers={headers}
        title="Website categorie overzicht"
        endpoint={endpoint.websites}
        autoPageSize
        autoHeight
        displayAddIcon={appData?.websites?.length > 0}
        allowEdit={false}
        allowDuplicate={false}
        allowDelete={false}
        onRowClick={(params) => {
          navigate(`/website/${params.id}/categories`);
        }}
      />
    </PageContainer>
  );
};

export default WebsiteCategoriesOverview;
