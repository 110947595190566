const fields = [
  {
    field: "articleName",
    required: true,
    label: "Artikelnaam",
    col: 12,
    display: "textfield",
  },
  {
    field: "headCategory",
    required: true,
    label: "Categorie",
    col: 12,
    display: "autocomplete",
    noOptions: "Voeg eerst een website categorie toe",
    visible: false,
  },
  {
    field: "categoryDisplayName",
    label: "Categorie",
    showInForm: false,
  },
];

export default fields;
