import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";

function ConfirmDeleteDialog({
  openDialog = false,
  width = "1200px",
  title,
  handleSave,
  afterClose = () => {},
  loading = false,
}) {
  const [open, setOpen] = useState(openDialog);

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
    afterClose(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: width, // Set your width here
            },
          },
        }}
      >
        <DialogTitle sx={{ py: 3 }}>
          <Typography
            sx={{ fontSize: "2rem", marginBottom: 0, fontWeight: 700 }}
          >
            {title} verwijderen
          </Typography>
        </DialogTitle>
        <DialogContent>
          Dit kan niet ongedaan gemaakt worden. Weet je het zeker?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Sluiten</Button>
          <Button variant="contained" onClick={handleSave} disabled={loading}>
            <span>Verwijderen</span>
            {loading && (
              <CircularProgress size="1rem" sx={{ marginLeft: 0.7 }} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ConfirmDeleteDialog;
