import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SideBarItem from "./SideBarItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useLogout } from "../../../Hooks/Auth/useLogout";
import { useAuthContext } from "../../../Hooks/Auth/useAuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";
import LogoutIcon from "@mui/icons-material/Logout";
import logo from "../../../Assets/logo.png";
import { useAppDataContext } from "../../../Hooks/AppData/useAppDataContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import SideBarMenu from "./SideBarMenu";
import { useEffect } from "react";

const drawerWidth = 250;

const StyledLogo = styled("div")(({ theme }) => ({
  height: "37px",
  [theme.breakpoints.down("sm")]: {
    height: "29px",
  },
  cursor: "pointer",
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function DrawerSideBar({ children }) {
  const { logout } = useLogout();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [websiteName, setWebsiteName] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { user } = useAuthContext();
  const { appData } = useAppDataContext();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const { websiteId } = useParams();

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (event) => {
    sessionStorage.setItem("route", window.location.pathname);
  };

  useEffect(() => {
    const savedRoute = sessionStorage.getItem("route");
    if (savedRoute) {
      sessionStorage.removeItem("route");
      navigate(savedRoute);
    }
  }, [navigate]);

  useEffect(() => {
    const website = appData?.websites?.find((site) => site._id === websiteId);

    setWebsiteName(website?.websiteName || null);
  }, [websiteId]);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function handleLogout() {
    handleCloseUserMenu();
    logout();
  }

  function handleLogoClick() {
    if (appData.websites.length === 1) {
      navigate(`/website/${appData.websites[0]._id}`);
    } else {
      navigate("/");
    }
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ background: theme.palette.secondary.main }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <StyledLogo onClick={handleLogoClick}>
            <img src={logo} alt="MissionControl" style={{ height: "100%" }} />
          </StyledLogo>
          {websiteName && (
            <MenuItem component={Link} to={`/website/${websiteId}`}>
              <Typography textAlign="center">{websiteName}</Typography>
            </MenuItem>
          )}
          {/*<Box*/}
          {/*  sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}*/}
          {/*  justifyContent="flex-end"*/}
          {/*>*/}
          {/*  <Tooltip title="Menu openen">*/}
          {/*    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>*/}
          {/*      <Avatar*/}
          {/*        alt={user?.email?.toUpperCase() || ""}*/}
          {/*        src="/static/images/avatar/2.jpg"*/}
          {/*      />*/}
          {/*    </IconButton>*/}
          {/*  </Tooltip>*/}
          {/*  <Menu*/}
          {/*    sx={{ mt: "45px" }}*/}
          {/*    id="menu-appbar"*/}
          {/*    anchorEl={anchorElUser}*/}
          {/*    anchorOrigin={{*/}
          {/*      vertical: "top",*/}
          {/*      horizontal: "right",*/}
          {/*    }}*/}
          {/*    keepMounted*/}
          {/*    transformOrigin={{*/}
          {/*      vertical: "top",*/}
          {/*      horizontal: "right",*/}
          {/*    }}*/}
          {/*    open={Boolean(anchorElUser)}*/}
          {/*    onClose={handleCloseUserMenu}*/}
          {/*  >*/}
          {/*    <Link*/}
          {/*      to="/websites"*/}
          {/*      style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}*/}
          {/*    >*/}
          {/*      <MenuItem key="settings" onClick={handleCloseUserMenu}>*/}
          {/*        <LanguageIcon style={{ marginRight: "8px", color: "grey" }} />{" "}*/}
          {/*        <Typography textAlign="center" style={{ color: "inherit" }}>*/}
          {/*          Domeinen*/}
          {/*        </Typography>*/}
          {/*      </MenuItem>*/}
          {/*    </Link>*/}
          {/*    <Link*/}
          {/*      to="/website-categories-overview"*/}
          {/*      style={{*/}
          {/*        textDecoration: "none",*/}
          {/*        color: "rgba(0, 0, 0, 0.87)",*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <MenuItem key="settings" onClick={handleCloseUserMenu}>*/}
          {/*        <CategoryIcon style={{ marginRight: "8px", color: "grey" }} />{" "}*/}
          {/*        <Typography textAlign="center" style={{ color: "inherit" }}>*/}
          {/*          Categorieën*/}
          {/*        </Typography>*/}
          {/*      </MenuItem>*/}
          {/*    </Link>*/}

          {/*    <Link*/}
          {/*      to="/monitoring"*/}
          {/*      style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}*/}
          {/*    >*/}
          {/*      <MenuItem key="settings" onClick={handleCloseUserMenu}>*/}
          {/*        <RemoveRedEyeIcon*/}
          {/*          style={{ marginRight: "8px", color: "grey" }}*/}
          {/*        />{" "}*/}
          {/*        <Typography textAlign="center" style={{ color: "inherit" }}>*/}
          {/*          Monitoring*/}
          {/*        </Typography>*/}
          {/*      </MenuItem>*/}
          {/*    </Link>*/}
          {/*    <Divider />*/}
          {/*    <MenuItem key="logout" onClick={handleLogout}>*/}
          {/*      <LogoutIcon style={{ marginRight: "8px", color: "grey" }} />{" "}*/}
          {/*      <Typography textAlign="center">Uitloggen</Typography>*/}
          {/*    </MenuItem>*/}
          {/*  </Menu>*/}
          {/*</Box>*/}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            overflowX: "hidden",
            boxSizing: "border-box",
            background: theme.palette.secondary.dark,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon sx={{ color: "white" }} />
            ) : (
              <ChevronRightIcon sx={{ color: "white" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ borderColor: "white" }} />
        <List>
          <SideBarMenu />
        </List>
        {process.env.NODE_ENV === "development" && (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              padding: "8px",
              justifyContent: "center",
              background: "#ff5733",
              height: "70px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                color: "white",
                margin: 0,
                fontWeight: 700,
                fontSize: "1.5rem",
              }}
            >
              DEV
            </p>
          </div>
        )}
      </Drawer>

      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
}
