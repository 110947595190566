import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { CircularProgress } from "@mui/material";

export default function CustomAccordion({
  children,
  deleteKey,
  title,
  subTitle,
  allowDelete = true,
  disabled = false,
  loading = false,
  id,
  updateExpandedKey,
  expand = false,
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    setExpanded(expand === id);
  }, [expand]);

  const handleChange = (panel) => (event, isExpanded) => {
    updateExpandedKey(panel);
    if (expanded && expand === id) {
      updateExpandedKey(null);
    }
  };

  return (
    <div>
      <Accordion
        expanded={expand === id}
        onChange={handleChange(id)}
        id={id}
        disabled={disabled}
        sx={expand === id ? { marginY: "16px !important" } : {}}
        onKeyDown={handleKeyPress}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ alignItems: "center" }}
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>{title}</Typography>
          <Typography sx={{ width: "67%", color: "text.secondary" }}>
            {subTitle}
          </Typography>
          {allowDelete && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                width: "10%",
                paddingRight: 2,
              }}
            >
              {loading && <CircularProgress size="1.3rem" />}
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  deleteKey(id);
                }}
                disabled={loading || disabled}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          )}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
}
