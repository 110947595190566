import LinkButton from "../../Components/UI/LinkButton";

const fields = [
  {
    field: "retailerName",
    label: "Verkoper",

    showInForm: false,
    width: 125,
  },
  {
    field: "domain",
    label: "Domein",

    showInForm: false,
    width: 150,
  },
  {
    field: "pageTitle",
    label: "Productnaam",
    col: 12,
    showInForm: false,
  },
  {
    field: "date",
    label: "Datum",
    col: 12,
    display: "textfield",
    showInForm: false,
    width: 75,
    renderCell: (params) => formatDate(params.row.date),
  },
  {
    field: "url",
    sortable: false,
    required: true,
    label: "Url",
    col: 12,
    width: 75,
    display: "textfield",
    renderCell: (params) => <LinkButton value={params.value} />,
  },
];

const formatDate = (dateInput) => {
  if (!dateInput) {
    return;
  }
  const date = new Date(dateInput);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const formattedDay = day < 10 ? "0" + day : day;
  const formattedMonth = month < 10 ? "0" + month : month;
  return `${formattedDay}-${formattedMonth}`;
};

export default fields;
