import { Autocomplete, Breadcrumbs } from "@mui/material";
import Box from "@mui/material/Box";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import BaseDialog from "./UI/BaseDialog";
import { useEffect, useState } from "react";
import CustomAutocomplete from "./UI/CustomAutocomplete";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { useAxios } from "../Hooks/useAxios";
import endpoint from "../Utils/endpoint";
import { useParams } from "react-router-dom";
import { useAppDataContext } from "../Hooks/AppData/useAppDataContext";

const CategoryBreadCrumbs = ({ article, refreshArticle }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { Axios } = useAxios();
  const { websiteId, articleId } = useParams();
  const [options, setOptions] = useState({});
  const { appData } = useAppDataContext();

  useEffect(() => {
    async function getOptions() {
      const response = await Axios(
        `${endpoint.contentArticle}/website/${websiteId}/article/${articleId}/categoryOptions`,
        "GET",
      );

      setOptions(response.data);
    }
    getOptions();
  }, []);

  function handleCategoryClick() {
    setOpenDialog(true);
  }

  const [category, setCategory] = useState({
    level2: "",
    level3: "",
    level4: "",
  });
  const [categoryTemporary, setCategoryTemporary] = useState({
    level2: "",
    level3: "",
    level4: "",
  });

  useEffect(() => {
    const catValues = {
      level2: article.category?.level2,
      level3: article.category?.level3,
      level4: article.category?.level4,
    };
    setCategory(catValues);
    setCategoryTemporary(catValues);
  }, [article]);

  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      {article.websiteName}
    </Typography>,
    <Typography key="3" color="text.primary">
      {article.categoryDisplayName}
    </Typography>,
    category?.level2 && (
      <Typography key="3" color="text.primary">
        {category.level2}
      </Typography>
    ),
    category?.level3 && (
      <Typography key="3" color="text.primary">
        {category.level3}
      </Typography>
    ),
    category?.level4 && (
      <Typography key="3" color="text.primary">
        {category.level4}
      </Typography>
    ),
  ];

  function handleAutocomplete(event, newValue, name) {
    setCategoryTemporary((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  }

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ width: "21%" }}>
        <CustomAutocomplete
          label="Hoofdcategorie"
          disabled
          value={article.categoryDisplayName}
          freeSolo={true}
        />
      </Box>
      <Box
        sx={{
          width: "5%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DoubleArrowIcon />
      </Box>
      <Box sx={{ width: "21%" }}>
        <CustomAutocomplete
          label="Merk"
          onChange={handleAutocomplete}
          onInputChange={(event, newValue) =>
            handleAutocomplete(event, newValue, "level2")
          }
          options={options.level2}
          value={categoryTemporary["level2"]}
          freeSolo={true}
          name={"level2"}
        />
      </Box>
      <Box
        sx={{
          width: "5%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DoubleArrowIcon />
      </Box>
      <Box sx={{ width: "21%" }}>
        <CustomAutocomplete
          label="Uitvoering"
          onChange={handleAutocomplete}
          onInputChange={(event, newValue) =>
            handleAutocomplete(event, newValue, "level3")
          }
          options={options.level3}
          value={categoryTemporary["level3"]}
          freeSolo={true}
          name={"level3"}
        />
      </Box>
      <Box
        sx={{
          width: "5%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DoubleArrowIcon />
      </Box>
      <Box sx={{ width: "21%" }}>
        <CustomAutocomplete
          label="Kleur"
          onChange={handleAutocomplete}
          onInputChange={(event, newValue) =>
            handleAutocomplete(event, newValue, "level4")
          }
          options={options.level4}
          value={categoryTemporary["level4"]}
          freeSolo={true}
          name={"level4"}
        />
      </Box>
    </Box>
  );

  const [triggerClose, setTriggerClose] = useState(false);

  function afterClose() {
    setTriggerClose(true);
    setCategoryTemporary(category);
  }

  async function handleSave() {
    if (invalid) return;
    setCategory(categoryTemporary);
    await Axios(
      `${endpoint.contentArticle}/website/${websiteId}/article/${articleId}`,
      "POST",
      { category: categoryTemporary },
    );
    refreshArticle();
    setTriggerClose(true);
  }

  useEffect(() => {
    setOpenDialog(false);
    setTriggerClose(false);
  }, [triggerClose]);

  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    if (categoryTemporary.level3 && !categoryTemporary.level2) {
      setInvalid(true);
    } else if (
      categoryTemporary.level4 &&
      (!categoryTemporary.level2 || !categoryTemporary.level3)
    ) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [categoryTemporary]);

  return (
    <Box
      onClick={handleCategoryClick}
      sx={{
        marginTop: 1,
        marginBottom: 1,
        cursor: "pointer",
      }}
    >
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        {breadcrumbs}
      </Breadcrumbs>

      <BaseDialog
        openDialog={openDialog}
        disableHandleSave={invalid}
        content={content}
        title="Categorie selecteren"
        afterClose={afterClose}
        handleSave={handleSave}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSave();
          }
        }}
      />
    </Box>
  );
};

export default CategoryBreadCrumbs;
