import BaseDialog from "./UI/BaseDialog";
import AiDialogContent from "./AiDialogContent";
import { useRef, useState } from "react";

const AiDialog = ({
  openDialog,
  afterClose,
  displayLoader,
  initialAiResponse,
  loadingAccordion,
  refreshArticle,
}) => {
  const [disableSave, setDisableSave] = useState(false);

  function disableSaveButton(state) {
    setDisableSave(state);
  }

  const contentRef = useRef();

  async function handleSave() {
    await contentRef.current.handleSave();
    afterClose();
  }

  return (
    <BaseDialog
      disableEnterToSave={true}
      openDialog={openDialog}
      title="AI content bewerken🧠"
      content={
        <AiDialogContent
          close={afterClose}
          displayLoader={displayLoader}
          setDisableSave={disableSaveButton}
          initialAiResponse={initialAiResponse}
          loadingAccordion={loadingAccordion}
          ref={contentRef}
          refreshArticle={refreshArticle}
        />
      }
      afterClose={afterClose}
      disableHandleSave={disableSave}
      handleSave={handleSave}
    />
  );
};

export default AiDialog;
