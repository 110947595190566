import React, { useState, useEffect } from "react";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Paper from "@mui/material/Paper";
import { useAxios } from "../Hooks/useAxios";
import endpoint from "../Utils/endpoint";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";

export default function MyRichTextEditor({ refreshArticle, article }) {
  const { articleId, websiteId } = useParams();
  const { Axios } = useAxios();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const getHtml = () => {
    const currentContent = editorState.getCurrentContent();
    return stateToHTML(currentContent);
  };

  const setHtmlContent = (htmlContent) => {
    const blocksFromHTML = convertFromHTML(htmlContent);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    setEditorState(EditorState.createWithContent(contentState));
  };

  const onBlurHandler = async () => {
    const html = getHtml();
    const response = await Axios(
      `${endpoint.contentArticle}/website/${websiteId}/article/${articleId}/`,
      "POST",
      {
        longProductDescription: html,
      },
    );

    if (response.status === 200) {
      enqueueSnackbar("Beschrijving succesvol opgeslagen", {
        variant: "success",
      });
      refreshArticle();
    } else {
      enqueueSnackbar("Er ging iets mis met het opslaan", { variant: "error" });
    }
  };

  useEffect(() => {
    const htmlContentFromDatabase = article.longProductDescription || "";
    setHtmlContent(htmlContentFromDatabase);
  }, [article]);

  return (
    <Paper elevation={1} sx={{ mb: 2 }}>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        style={{ padding: "0 20px" }}
        onBlur={onBlurHandler}
      />
    </Paper>
  );
}
