import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";

function ConfirmDeleteDialog({
  openDialog = false,
  width = "1200px",
  title,
  content,
  handleSave,
  afterclose = () => {},
  confirmText,
}) {
  const [open, setOpen] = useState(openDialog);

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
    afterclose(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: width, // Set your width here
            },
          },
        }}
      >
        <DialogTitle sx={{ py: 3 }}>
          <Typography variant="h3" sx={{ marginBottom: 0 }}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Sluiten</Button>
          <Button variant="contained" onClick={handleSave}>
            {confirmText || "Opslaan"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ConfirmDeleteDialog;
