import AddEditDialog from "./AddEditDialog";
import fields from "../../Utils/Fields/websiteCategoryFields";
import endpoint from "../../Utils/endpoint";
import { useParams } from "react-router-dom";

const AddEditWebsiteCategoryDialog = ({
  openDialog,
  afterClose = () => {},
  dialogMode,
  patchData,
  transformData,
}) => {
  const { websiteId } = useParams();

  return (
    <AddEditDialog
      openDialog={openDialog}
      title="Website categorieën"
      singular="Website categorie"
      fields={fields}
      afterClose={afterClose}
      width="900px"
      endpoint={`${endpoint.category}/${websiteId}`}
      dialogMode={dialogMode}
      patchData={patchData}
      transformData={transformData}
      customAppDataName={`categories-${websiteId}`}
    />
  );
};

export default AddEditWebsiteCategoryDialog;
