import WebsiteOverview from "../Components/WebsiteOverview";
import { useAppDataContext } from "../Hooks/AppData/useAppDataContext";

const WebsiteMonitoringOverview = () => {
  const { appData } = useAppDataContext();
  return (
    <WebsiteOverview
      title="Website Monitoring"
      baseNavUrl="/website/id/monitoring"
      url="/monitoring"
      displayAddIcon={appData?.websites?.length > 0}
    />
  );
};

export default WebsiteMonitoringOverview;
