import { useEffect, useState } from "react";
import DataTable from "./UI/DataTable";
import { DialogModes } from "../Utils/DialogModes";
import headers from "../Utils/Fields/affiliateFields";
import AddEditAffiliateDialog from "./Dialogs/AddEditAffiliateDialog";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import endpoint from "../Utils/endpoint";
import ScrapeContent from "./ScrapeContent";
import { useFetchTableData } from "../Hooks/AppData/useFetchTableData";
import ProductPrice from "./ProductPrice";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { useAxios } from "../Hooks/useAxios";
import BaseDialog from "./UI/BaseDialog";
import BulkAddAffiliates from "./BulkAddAffiliates";
import { useSnackbar } from "notistack";

const Affiliates = ({ article, refreshArticle }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [openBulkDialog, setOpenBulkDialog] = useState(false);
  const [alreadyCheckedPrices, setAlreadyCheckedPrices] = useState({});
  const { websiteId, articleId } = useParams();
  const [disableBulkSave, setDisableBulkSave] = useState(false);
  const [numberOfAffiliates, setNumberOfAffiliates] = useState(null);
  const { Axios } = useAxios();

  function handleClose() {
    setOpenDialog(false);
  }

  function addToCheckedPrices(id) {
    setAlreadyCheckedPrices((prevState) => {
      return { ...prevState, [id]: true };
    });
  }

  useEffect(() => {
    if (Object.keys(alreadyCheckedPrices).length === numberOfAffiliates) {
      fetchTableData(
        `${endpoint.affiliates}/website/${websiteId}/article/${articleId}`,
        "affiliates",
      );
      refreshArticle();
    }
  }, [alreadyCheckedPrices]);

  const [patchCopyData, setPatchCopyData] = useState({});

  const handleEditCopy = (rowData) => {
    setPatchCopyData(rowData);
    setOpenDialog(true);
  };

  const { fetchTableData } = useFetchTableData();

  const [enhancedHeaders, setEnhancedHeaders] = useState(headers);

  useEffect(() => {
    const updatedHeaders = [...headers];

    updatedHeaders.push({
      field: "content",
      showInForm: false,
      label: "Content",
      width: 75,
      sortable: false,
      display: "textfield",
      renderCell: (params) => (
        <ScrapeContent
          params={params}
          article={article}
          refreshArticle={refreshArticle}
        />
      ),
    });

    updatedHeaders.push({
      field: "price",
      showInForm: false,
      label: "Prijs",
      width: 100,
      renderCell: (params) => (
        <ProductPrice
          params={params}
          addToCheckedPrices={addToCheckedPrices}
          alreadyCheckedPrices={alreadyCheckedPrices}
          articleId={articleId}
          refreshArticle={refreshArticle}
        />
      ),
    });

    setEnhancedHeaders(updatedHeaders);
  }, [headers, article, alreadyCheckedPrices]);

  async function handleOpenSearch() {
    setOpenBulkDialog(true);
  }

  const tableActions = [
    {
      label: "Bulk toevoegen",
      icon: <PlaylistAddIcon />,
      onClick: () => handleOpenSearch(),
    },
  ];

  function afterBulkClose() {
    setOpenBulkDialog(false);
    setSelectedUrls([]);
  }

  async function afterBulkSave() {
    setDisableBulkSave(true);

    try {
      const response = await Axios(
        `${endpoint.affiliates}/website/${websiteId}/article/${articleId}/bulk`,
        "POST",
        {
          urls: selectedUrls,
        },
      );

      if (response.status === 200) {
        enqueueSnackbar("Verkopers zijn succesvol toegevoegd", {
          variant: "success",
        });

        const affiliates = await fetchTableData(
          `${endpoint.affiliates}/website/${websiteId}/article/${articleId}`,
          "affiliates",
        );
        setNumberOfAffiliates(affiliates.length);

        setOpenBulkDialog(false);
        setDisableBulkSave(false);
      } else {
        enqueueSnackbar(
          "Er ging iets fout met het toevoegen van de verkopers",
          {
            variant: "success",
          },
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  const [selectedUrls, setSelectedUrls] = useState([]);

  function sendUrlsToParent(urls) {
    setSelectedUrls(urls);
  }

  return (
    <Box>
      <DataTable
        headers={enhancedHeaders}
        title="Affiliates"
        singular="Affiliate"
        autoPageSize
        autoHeight
        onEditCopyRow={handleEditCopy}
        displayAddIcon
        allowDuplicate={false}
        endpoint={`${endpoint.affiliates}/website/${websiteId}/article/${articleId}`}
        customAppDataName="affiliates"
        density="compact"
        initialSort={{ field: "price", sort: "asc" }}
        tableActions={tableActions}
        afterDelete={() => refreshArticle()}
      />
      <AddEditAffiliateDialog
        openDialog={openDialog}
        afterClose={handleClose}
        patchData={patchCopyData.row}
        dialogMode={DialogModes[patchCopyData.mode]}
        afterSave={() => refreshArticle()}
      />
      <BaseDialog
        disableEnterToSave={true}
        openDialog={openBulkDialog}
        title="Bulk affiliates toevoegen"
        content={
          <BulkAddAffiliates
            article={article}
            sendUrlsToParent={sendUrlsToParent}
          />
        }
        afterClose={afterBulkClose}
        disableHandleSave={disableBulkSave}
        loading={disableBulkSave}
        handleSave={afterBulkSave}
        width={"2000px"}
      />
    </Box>
  );
};

export default Affiliates;
