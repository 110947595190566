const fields = [
  {
    field: "description",
    label: "Kenmerk",
    col: 12,
    display: "textfield",
    rows: 2,
    required: true,
  },
];

export default fields;
