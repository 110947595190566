import AddEditWebsiteDialog from "../Components/Dialogs/AddEditWebsiteDialog";
import { useEffect, useState } from "react";
import { useRefresh } from "../Hooks/useRefresh";
import DataTable from "../Components/UI/DataTable";
import headers from "../Utils/Fields/websiteCategoryFields";
import { DialogModes } from "../Utils/DialogModes";
import PageContainer from "../Components/UI/PageContainer";
import endpoint from "../Utils/endpoint";
import AddEditWebsiteCategoryDialog from "../Components/Dialogs/AddEditWebsiteCategoryDialog";
import { useAppDataContext } from "../Hooks/AppData/useAppDataContext";
import { useParams } from "react-router-dom";

const WebsiteCategoryPage = () => {
  useRefresh();
  const { appData } = useAppDataContext();
  const [openDialog, setOpenDialog] = useState(false);
  const { websiteId } = useParams();

  function handleClose() {
    setOpenDialog(false);
  }

  const [dataTableName, setDataTableName] = useState("Website categorieën");

  useEffect(() => {
    const website = appData.websites.find(
      (website) => website._id === websiteId,
    );
    setDataTableName(`${website.websiteName} categorieën`);
  }, []);

  const [patchCopyData, setPatchCopyData] = useState({});

  const handleEditCopy = (rowData) => {
    setPatchCopyData(rowData);
    setOpenDialog(true);
  };

  function transformData(data) {
    return {
      ...data,
      websiteId,
    };
  }

  return (
    <PageContainer>
      <DataTable
        headers={headers}
        title={dataTableName}
        singular="Categorie"
        endpoint={`${endpoint.category}/${websiteId}`}
        autoPageSize
        autoHeight
        onEditCopyRow={handleEditCopy}
        displayAddIcon
        allowDuplicate={false}
        customAppDataName={`categories-${websiteId}`}
      />
      <AddEditWebsiteCategoryDialog
        openDialog={openDialog}
        afterClose={handleClose}
        patchData={patchCopyData.row}
        dialogMode={DialogModes[patchCopyData.mode]}
        transformData={transformData}
      />
    </PageContainer>
  );
};

export default WebsiteCategoryPage;
