import AddEditDialog from "./AddEditDialog";
import fields from "../../Utils/Fields/articleOverviewFields";
import endpoint from "../../Utils/endpoint";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import headers from "../../Utils/Fields/articleOverviewFields";
import { useAppDataContext } from "../../Hooks/AppData/useAppDataContext";

const AddEditArticleDialog = ({
  openDialog,
  afterClose = () => {},
  dialogMode,
  patchData,
}) => {
  const { websiteId } = useParams();
  const navigate = useNavigate();
  const { appData } = useAppDataContext();

  function transformData(data) {
    return {
      ...data,
      websiteId: websiteId,
    };
  }

  function afterCreate(response) {
    if (response.data._id) {
      navigate(`/website/${websiteId}/article/${response.data._id}`);
    }
  }

  const [customHeaders, setCustomHeaders] = useState([]);

  useEffect(() => {
    if (appData) {
      const updatedHeaders = headers.map((header) => {
        if (
          appData[`categories-${websiteId}`] &&
          header.field === "headCategory"
        ) {
          const categoryOptions = appData[`categories-${websiteId}`].map(
            (category) => {
              return {
                label: category.categoryDisplayName,
                value: category._id,
                key: category._id,
              };
            },
          );
          return { ...header, options: categoryOptions };
        }
        return header;
      });
      setCustomHeaders(updatedHeaders);
    }
  }, [appData]);

  return (
    <AddEditDialog
      openDialog={openDialog}
      title="Artikelen"
      singular="Artikel"
      fields={customHeaders}
      afterClose={afterClose}
      width="900px"
      endpoint={`${endpoint.articles}/website/${websiteId}`}
      dialogMode={dialogMode}
      patchData={patchData}
      transformData={transformData}
      afterCreate={afterCreate}
    />
  );
};

export default AddEditArticleDialog;
