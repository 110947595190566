import { createContext, useReducer, useEffect } from "react";

export const AppDataContext = createContext();

export const appDataReducer = (state, action) => {
    switch (action.type) {
        case "SET_APP_DATA":
            return {
                ...state,
                appData: {
                    ...state.appData,
                    [action.payload.dataName]: action.payload.data,
                },
            };
        default:
            return state;
    }
};

export const AppDataContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(appDataReducer, {
        appData: null,
    });

    useEffect(() => {
        console.log(state);
    }, [state]);

    return (
        <AppDataContext.Provider value={{ ...state, dispatch }}>
            {children}
        </AppDataContext.Provider>
    );
};
