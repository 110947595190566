import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import DescriptionIcon from "@mui/icons-material/Description";
import AiCard from "./AiCard";

export default function ShortDescriptionList({
  content = [],
  updateAiContent,
  objectKey,
  requestRevision,
}) {
  const [selectedIndex, setSelectedIndex] = React.useState();

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    updateAiContent(objectKey, index);
  };

  return (
    <AiCard objectKey={objectKey} requestRevision={requestRevision}>
      <List component="nav">
        {content[objectKey] &&
          content[objectKey].map((item, index) => {
            return (
              <Box key={index}>
                <ListItemButton
                  selected={selectedIndex === index}
                  onClick={(event) => handleListItemClick(event, index)}
                >
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary={item} />
                </ListItemButton>
                <Divider />
              </Box>
            );
          })}
      </List>
    </AiCard>
  );
}
