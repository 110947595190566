import AddEditDialog from "./AddEditDialog";
import fields from "../../Utils/Fields/specificationFields";
import endpoint from "../../Utils/endpoint";
import { useParams } from "react-router-dom";

const AddEditSpecificationDialog = ({
  openDialog,
  afterClose = () => {},
  dialogMode,
  patchData,
  afterSave,
}) => {
  const { websiteId, articleId } = useParams();

  function transformData(data) {
    return { specifications: data };
  }

  return (
    <AddEditDialog
      openDialog={openDialog}
      title="Affiliates"
      singular="Affiliate"
      fields={fields}
      afterClose={afterClose}
      width="600px"
      endpoint={`${endpoint.specifications}/website/${websiteId}/article/${articleId}`}
      dialogMode={dialogMode}
      patchData={patchData}
      afterSave={afterSave}
      transformData={transformData}
      customAppDataName="specifications"
    />
  );
};

export default AddEditSpecificationDialog;
