import React from "react";
import { Container, Fade } from "@mui/material";

const PageContainer = ({ children, width }) => {
  return (
    <Fade in={true}>
      <Container
        maxWidth={width || "lg"}
        component="main"
        sx={{ overflowX: "auto", marginTop: 5 }}
      >
        {children}
      </Container>
    </Fade>
  );
};

export default PageContainer;
