import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ConfirmDialog from "./UI/ConfirmDialog";
import endpoint from "../Utils/endpoint";
import { useEffect, useState } from "react";
import { useAxios } from "../Hooks/useAxios";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import DrawIcon from "@mui/icons-material/Draw";
import Tooltip from "@mui/material/Tooltip";

const PublishButton = ({ article, displayLoader, refreshArticle }) => {
  const { articleId, websiteId } = useParams();
  const [openConfirmCancelDialog, setOpenConfirmCancelDialog] = useState(false);
  const { Axios } = useAxios();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  async function publishArticle() {
    let validationErrors = [
      "Dit artikel kan niet gepubliceerd worden",
      "Los de volgende problemen op:",
    ];

    if (!article.articleName) {
      validationErrors.push("👉 Artikelnaam is verplicht");
    }
    if (article.imageSequence.length < 1) {
      validationErrors.push("👉 Voeg minimaal 1 afbeelding toe");
    }
    if (!article?.category?.level2) {
      validationErrors.push("👉 Categorie level 2 is verplicht");
    }
    if (!article?.category?.level3) {
      validationErrors.push("👉 Categorie level 3 is verplicht");
    }
    if (!article.recommendedRetailPrice) {
      validationErrors.push("👉 Adviesprijs is verplicht");
    }
    if (!article.slug) {
      validationErrors.push("👉 Slug is verplicht");
    }
    if (!article.metaDescription) {
      validationErrors.push("👉 Metabeschrijving is verplicht");
    }
    if (!article.productDescription) {
      validationErrors.push("👉 Korte productbeschrijving is verplicht");
    }
    if (article.affiliates.length < 1) {
      console.log(article.affiliates);
      validationErrors.push("👉 Voeg minimaal 1 affiliate toe");
    }
    if (article.specifications.length < 1) {
      validationErrors.push("👉 Voeg minimaal 1 specificatie toe");
    }
    if (article.features.length < 1) {
      validationErrors.push("👉 Voeg minimaal 1 productkenmerk toe");
    }

    function stripHtmlTags(html) {
      return html;
    }

    if (
      !article.longProductDescription ||
      article.longProductDescription.trim().replace(/<[^>]*>?/gm, "") === ""
    ) {
      validationErrors.push("👉 Voeg een (lange) productbeschrijving toe");
    }

    if (validationErrors.length > 2) {
      enqueueSnackbar(validationErrors.join("\n"), {
        variant: "error",
        style: { whiteSpace: "pre-line" },
      });

      return;
    }

    displayLoader(true);
    enqueueSnackbar("Artikel wordt gepubliceerd", { variant: "default" });
    const response = await Axios(
      `${endpoint.contentArticle}/website/${websiteId}/article/${articleId}/publish`,
      "POST",
      { published: true },
    );

    if (response?.status === 200) {
      enqueueSnackbar("Artikel succesvol gepubliceerd!", {
        variant: "success",
      });
      refreshArticle();
    } else {
      enqueueSnackbar("Er ging iets mis met het publiceren", {
        variant: "error",
      });
    }
    displayLoader(false);
  }

  const [alert, setAlert] = useState(false);

  useEffect(() => {
    if (article.lastSaved && !article.lastPublished) {
      setAlert(true);
    } else if (new Date(article.lastSaved) > new Date(article.lastPublished)) {
      setAlert(true);
    } else {
      setAlert(false);
    }
  }, [article]);

  // Use this for test purposes
  // eslint-disable-next-line
  async function scrape() {
    await Axios(
      `${endpoint.contentArticle}/website/${websiteId}/article/${articleId}/getPrice`,
      "GET",
    );
  }

  async function toConcept() {
    displayLoader(true);
    enqueueSnackbar("Artikel wordt omgezet naar concept", {
      variant: "default",
    });
    const response = await Axios(
      `${endpoint.contentArticle}/website/${websiteId}/article/${articleId}/publish`,
      "POST",
      { published: false },
    );

    if (response?.status === 200) {
      enqueueSnackbar("Artikel succesvol omgezet naar concept!", {
        variant: "success",
      });
      refreshArticle();
    } else {
      enqueueSnackbar("Er ging iets mis met het omzetten naar concept", {
        variant: "error",
      });
    }
    displayLoader(false);
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}
      >
        {alert && (
          <Alert
            severity="warning"
            sx={{ marginRight: 2, paddingTop: 0, paddingBottom: 0 }}
          >
            Er zijn ongeplubiceerde wijzigingen
          </Alert>
        )}
        {/*<Button variant="contained" onClick={scrape} sx={{ marginRight: 2 }}>*/}
        {/*  Scrape*/}
        {/*</Button>*/}
        {article.published && (
          <Box sx={{ marginRight: 2, display: "flex", alignItems: "center" }}>
            <Tooltip title="Laatste versie is gepubliceerd">
              <PublishedWithChangesIcon style={{ color: "green" }} />
            </Tooltip>
          </Box>
        )}
        {!article.published && article.lastPublished && (
          <Box sx={{ marginRight: 2, display: "flex", alignItems: "center" }}>
            <Tooltip title="Versie staat online als concept">
              <DrawIcon style={{ color: "red" }} />
            </Tooltip>
          </Box>
        )}
        {!article.published && !article.lastPublished && (
          <Box sx={{ marginRight: 2, display: "flex", alignItems: "center" }}>
            <Tooltip title="Ongepubliceerd">
              <UnpublishedIcon color="secondary" />
            </Tooltip>
          </Box>
        )}

        {article.wordpressPostId && article.published && (
          <Button
            variant="outlined"
            sx={{ marginRight: 2 }}
            onClick={toConcept}
          >
            Naar concept omzetten
          </Button>
        )}
        <Button variant="contained" onClick={publishArticle}>
          Publiceren
        </Button>
      </Box>

      <ConfirmDialog
        openDialog={openConfirmCancelDialog}
        title="Weet je het zeker?"
        content="Alle wijzigingen zullen niet worden opgeslagen"
        width="600px"
        afterclose={() => setOpenConfirmCancelDialog(false)}
        confirmText="Annuleren"
        handleSave={() => navigate(`/website/${websiteId}`)}
      />
    </Box>
  );
};

export default PublishButton;
