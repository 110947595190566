export const DialogModes = {
    new: "new",
    edit: "edit",
    copy: "copy",
};

export const DialogLabels = {
    new: "toevoegen",
    edit: "bewerken",
    copy: "dupliceren",
};

export const DialogMessages = {
    new: "toegevoegd",
    edit: "bewerkt",
    copy: "geduplicieerd",
};
