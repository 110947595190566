import PageContainer from "./PageContainer";
import DataTable from "./DataTable";
import AddEditDialog from "./BaseDialog";
import { useState } from "react";
import { DialogModes } from "../../Utils/DialogModes";

const GeneralAddEditDatatable = ({
  headers,
  title,
  singular,
  endpoint,
  customAppDataName,
  displayAddIcon,
  allowDuplicate,
  allowEdit,
  allowDelete,
  afterClose,
  transformData,
  density,
  initialSort,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [patchCopyData, setPatchCopyData] = useState({});

  const handleEditCopy = (rowData) => {
    setPatchCopyData(rowData);
    setOpenDialog(true);
  };

  return (
    <PageContainer>
      <DataTable
        initialSort={initialSort}
        density={density}
        headers={headers}
        title={title}
        singular={singular}
        endpoint={endpoint}
        autoPageSize
        autoHeight
        onEditCopyRow={handleEditCopy}
        displayAddIcon={displayAddIcon}
        allowDuplicate={allowDuplicate}
        allowEdit={allowEdit}
        allowDelete={allowDelete}
        customAppDataName={customAppDataName}
      />
      <AddEditDialog
        headers={headers}
        title={title}
        singular={singular}
        endpoint={endpoint}
        openDialog={openDialog}
        afterClose={afterClose}
        patchData={patchCopyData.row}
        dialogMode={DialogModes[patchCopyData.mode]}
        transformData={transformData}
        customAppDataName={customAppDataName}
      />
    </PageContainer>
  );
};

export default GeneralAddEditDatatable;
