import LinkButton from "../../Components/UI/LinkButton";
import dayjs from "dayjs";

const fields = [
  {
    field: "productName",
    label: "Naam",
    width: 500,
  },
  {
    field: "category",
    label: "Categorie",
  },
  {
    field: "retailer",
    label: "Winkel",
  },
  {
    field: "date",
    label: "Datum toegevoegd",
    width: 150,
    renderCell: (params) => dayjs(params.value).format("DD-MM-YY"),
  },
  {
    field: "url",
    label: "Url",
    width: 75,
    renderCell: (params) => <LinkButton value={params.value} />,
  },
];

export default fields;
