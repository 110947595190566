import AddEditWebsiteDialog from "../Components/Dialogs/AddEditWebsiteDialog";
import { useState } from "react";
import { useRefresh } from "../Hooks/useRefresh";
import DataTable from "../Components/UI/DataTable";
import headers from "../Utils/Fields/websiteFields";
import { DialogModes } from "../Utils/DialogModes";
import PageContainer from "../Components/UI/PageContainer";
import endpoint from "../Utils/endpoint";

const WebsiteSettings = () => {
  useRefresh();
  const [openDialog, setOpenDialog] = useState(false);

  function handleClose() {
    setOpenDialog(false);
  }

  const [patchCopyData, setPatchCopyData] = useState({});

  const handleEditCopy = (rowData) => {
    setPatchCopyData(rowData);
    setOpenDialog(true);
  };

  return (
    <PageContainer>
      <DataTable
        headers={headers}
        title="Websites"
        endpoint={endpoint.websites}
        autoPageSize
        autoHeight
        onEditCopyRow={handleEditCopy}
        displayAddIcon
        allowDuplicate={false}
      />
      <AddEditWebsiteDialog
        openDialog={openDialog}
        afterClose={handleClose}
        patchData={patchCopyData.row}
        dialogMode={DialogModes[patchCopyData.mode]}
      />
    </PageContainer>
  );
};

export default WebsiteSettings;
