import TextField from "@mui/material/TextField";
import { Autocomplete, CircularProgress } from "@mui/material";
import { Fragment, useState } from "react";

const AutoComplete = ({
  onSelect,
  options = [],
  sx,
  label,
  multiple,
  disableCloseOnSelect,
  renderOption,
  placeholder,
  renderInput,
  getOptionValue,
  getOptionLabel,
  noOptionsText,
  isOptionEqualToValue,
}) => {
  const [selectedValue, setSelectedValue] = useState(multiple ? [] : null);

  const handleSelect = (event, value) => {
    setSelectedValue(value);
    onSelect(value);
  };

  return (
    <Autocomplete
      id="combo-box"
      options={options}
      sx={sx}
      style={{ paddingTop: "5px" }}
      noOptionsText={noOptionsText || "Geen opties gevonden"}
      renderInput={
        renderInput
          ? renderInput
          : (params) => (
              <TextField {...params} label={label} placeholder={placeholder} />
            )
      }
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      value={selectedValue}
      onChange={handleSelect}
      multiple={multiple}
      disableCloseOnSelect={disableCloseOnSelect}
      renderOption={renderOption}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
};

export default AutoComplete;
