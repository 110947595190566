import { useEffect, useState } from "react";
import { useAxios } from "../Hooks/useAxios";
import endpoint from "../Utils/endpoint";
import { useParams } from "react-router-dom";
import { useFetchTableData } from "../Hooks/AppData/useFetchTableData";
import headers from "../Utils/Fields/missingOfferFields";
import DataTable from "./UI/DataTable";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LinkButton from "./UI/LinkButton";

const BulkAddAffiliates = ({ article, sendUrlsToParent }) => {
  const [cloneRows, setCloneRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const { Axios } = useAxios();
  const { fetchTableData } = useFetchTableData();
  const { websiteId, articleId } = useParams();

  async function fetchAllRows() {
    try {
      const response = await Axios(
        `${endpoint.monitoredCategories}/website/${websiteId}/article/${articleId}/missingOffersForArticle`,
        "GET",
      );

      setRows(response.data);
      setCloneRows(response.data);
    } catch (error) {
      console.error("Error fetching rows:", error);
    }
  }

  useEffect(() => {
    fetchAllRows();
  }, []);

  function getSelectedRows(rows) {
    setSelectedRows(rows);
  }

  const [supportedRetailers, setSupportedRetailers] = useState([]);
  const [alreadySelected, setAlreadySelected, setRunSelectedOnce] = useState(
    [],
  );

  async function getAlreadySelectedAffiliates() {
    try {
      const response = await Axios(
        `${endpoint.monitoredCategories}/website/${websiteId}/article/${articleId}/selectedAffiliates`,
        "GET",
      );

      const selectedAffiliates = response.data;
      setAlreadySelected(selectedAffiliates);

      const enhancedRetailers = supportedRetailers.map((retailer) => {
        const matchRetailer = selectedAffiliates.find(
          (affiliate) => affiliate.domain === retailer.domain,
        );

        if (matchRetailer) {
          return matchRetailer;
        }
        return retailer;
      });

      setSupportedRetailers(enhancedRetailers);
    } catch (error) {
      console.error("Error fetching rows:", error);
    }
  }

  useEffect(() => {
    async function getSupportedDomains() {
      const supportedDomains = await fetchTableData(
        `${endpoint.contentArticle}/supportedDomains`,
        undefined,
      );

      const updatedRetailers = Object.keys(supportedDomains).map((key) => ({
        domain: key,
        name: supportedDomains[key],
      }));

      setSupportedRetailers(updatedRetailers);
    }
    getSupportedDomains();
  }, []);

  useEffect(() => {
    if (
      article.affiliates.length > 0 &&
      supportedRetailers.length > 0 &&
      alreadySelected.length === 0
    ) {
      getAlreadySelectedAffiliates();
    }
  }, [supportedRetailers]);

  useEffect(() => {
    const updateRetailers = supportedRetailers.map((retailer) => {
      const foundProduct = selectedRows.find((product) => {
        return product.retailer === retailer.domain;
      });

      const alreadyInAffiliates = alreadySelected.find((affiliate) => {
        return affiliate.domain === retailer.domain;
      });

      if (alreadyInAffiliates) return alreadyInAffiliates;

      if (foundProduct) {
        return {
          ...retailer,
          checked: true,
          url: foundProduct.url,
          productName: foundProduct.productName,
        };
      }
      delete retailer.url;
      return { ...retailer, checked: false };
    });

    const uniqueRetailers = Array.from(
      new Set(selectedRows.map((product) => product.retailer)),
    );
    setSupportedRetailers(updateRetailers);

    const selectedRowIds = selectedRows.map((item) => item._id);

    setRows(
      cloneRows.filter((row) => {
        const retailerIncluded = uniqueRetailers.includes(row.retailer);
        const idIncluded = selectedRowIds.includes(row._id);
        return !retailerIncluded || idIncluded;
      }),
    );
    // }
  }, [selectedRows]);

  useEffect(() => {
    const selectedUrls = selectedRows.map((row) => row.url);
    sendUrlsToParent(selectedUrls);
  }, [selectedRows]);

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Box sx={{ width: "80%", overflow: "auto" }}>
        <DataTable
          headers={headers}
          initialSort={{ field: "productName", sort: "asc" }}
          customData={rows}
          autoHeight
          allowDuplicate={false}
          density="compact"
          allowEdit={false}
          allowDelete={false}
          displayFilter
          checkboxSelection
          selectedRows={getSelectedRows}
          allowTableActions={false}
        />
      </Box>
      <Box sx={{ width: "20%", pl: 3 }}>
        {supportedRetailers &&
          supportedRetailers.map((retailer) => (
            <Box
              sx={{ display: "flex", alignItems: "center" }}
              key={retailer.domain}
            >
              <CheckCircleIcon
                fontSize="medium"
                sx={{
                  color: retailer.checked ? "green" : "lightGrey",
                }}
              />

              <span
                style={{
                  paddingBottom: "8px",
                  paddingTop: "8px",
                  paddingLeft: "6px",
                  fontSize: "12px",
                }}
              >
                {retailer.name}
              </span>
              {retailer.url && (
                <LinkButton value={retailer.url} size="medium" />
              )}
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default BulkAddAffiliates;
