import PageContainer from "../Components/UI/PageContainer";
import DataTable from "../Components/UI/DataTable";
import headers from "../Utils/Fields/monitoredCategoryFields";
import endpoint from "../Utils/endpoint";
import { DialogModes } from "../Utils/DialogModes";
import { useState } from "react";
import AddEditMonitoredCategoryDialog from "../Components/Dialogs/AddEditMonitoredCategoryDialog";
import { useParams } from "react-router-dom";
import { useAppDataContext } from "../Hooks/AppData/useAppDataContext";

const CategoryMonitoringSettings = () => {
  const { websiteId } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const { appData } = useAppDataContext();

  function handleClose() {
    setOpenDialog(false);
  }

  const [patchCopyData, setPatchCopyData] = useState({});

  const handleEditCopy = (rowData) => {
    setPatchCopyData(rowData);
    setOpenDialog(true);
  };

  return (
    <PageContainer>
      <DataTable
        headers={headers}
        title="Gemonitorde categorieën"
        endpoint={`${endpoint.monitoredCategories}/website/${websiteId}`}
        autoPageSize
        autoHeight
        onEditCopyRow={handleEditCopy}
        displayAddIcon={appData?.websites?.length > 0}
        allowDuplicate={false}
        singular="Categorie"
      />
      <AddEditMonitoredCategoryDialog
        openDialog={openDialog}
        afterClose={handleClose}
        patchData={patchCopyData.row}
        dialogMode={DialogModes[patchCopyData.mode]}
      />
    </PageContainer>
  );
};

export default CategoryMonitoringSettings;
