import { useNavigate } from "react-router-dom";
import PageContainer from "./UI/PageContainer";
import DataTable from "./UI/DataTable";
import headers from "../Utils/Fields/websiteOverviewFields";
import endpoint from "../Utils/endpoint";
import { useEffect } from "react";
import { useAppDataContext } from "../Hooks/AppData/useAppDataContext";

const WebsiteOverview = ({ title, baseNavUrl, url, displayAddIcon }) => {
  const navigate = useNavigate();
  const { appData } = useAppDataContext();

  function createUrl(rowId) {
    return baseNavUrl.replace("id", rowId);
  }

  useEffect(() => {
    if (appData?.websites?.length === 1) {
      navigate(createUrl(appData.websites[0]._id));
    } else {
      navigate(url);
    }
    // eslint-disable-next-line
  }, [appData]);

  return (
    <PageContainer>
      <DataTable
        headers={headers}
        title={title}
        endpoint={endpoint.websites}
        autoPageSize
        autoHeight
        displayAddIcon={displayAddIcon}
        allowEdit={false}
        allowDuplicate={false}
        allowDelete={false}
        onRowClick={(params) => {
          navigate(createUrl(params.id));
        }}
      />
    </PageContainer>
  );
};

export default WebsiteOverview;
