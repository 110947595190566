import ListItemButton from "@mui/material/ListItemButton";
import { Link, useParams } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import * as React from "react";
import LanguageIcon from "@mui/icons-material/Language";
import CategoryIcon from "@mui/icons-material/Category";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LogoutIcon from "@mui/icons-material/Logout";
import ArticleIcon from "@mui/icons-material/Article";
import { useLogout } from "../../../Hooks/Auth/useLogout";
import Divider from "@mui/material/Divider";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";

const SideBarMenu = () => {
  const { logout } = useLogout();
  const { websiteId } = useParams();

  function handleLogout() {
    logout();
  }

  return (
    <div>
      <ListItem disablePadding>
        <ListItemButton component={Link} to="/">
          <ListItemIcon sx={{ color: "white", minWidth: "40px" }}>
            <ArticleIcon />
          </ListItemIcon>
          <ListItemText
            primary="Artikelen"
            primaryTypographyProps={{
              sx: { color: "white", fontSize: "14px" },
            }}
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton component={Link} to="/websites">
          <ListItemIcon sx={{ color: "white", minWidth: "40px" }}>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText
            primary="Domeinen"
            primaryTypographyProps={{
              sx: { color: "white", fontSize: "14px" },
            }}
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton component={Link} to="/website-categories-overview">
          <ListItemIcon sx={{ color: "white", minWidth: "40px" }}>
            <CategoryIcon />
          </ListItemIcon>
          <ListItemText
            primary="Categorieën"
            primaryTypographyProps={{
              sx: { color: "white", fontSize: "14px" },
            }}
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          component={Link}
          to={websiteId ? `/website/${websiteId}/monitoring` : "/monitoring"}
        >
          <ListItemIcon sx={{ color: "white", minWidth: "40px" }}>
            <RemoveRedEyeIcon />
          </ListItemIcon>
          <ListItemText
            primary="Aanbod monitoring"
            primaryTypographyProps={{
              sx: { color: "white", fontSize: "14px" },
            }}
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ marginLeft: 3 }}>
        <ListItemButton
          component={Link}
          to={`/website/${websiteId}/monitored-categories`}
          disabled={!websiteId || false}
        >
          <ListItemIcon sx={{ color: "white", minWidth: "40px" }}>
            <EditIcon />
          </ListItemIcon>
          <ListItemText
            primary="Categorieën bewerken"
            primaryTypographyProps={{
              sx: { color: "white", fontSize: "14px" },
            }}
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ marginLeft: 3 }}>
        <ListItemButton
          component={Link}
          to={`/website/${websiteId}/linkList`}
          disabled={!websiteId}
        >
          <ListItemIcon sx={{ color: "white", minWidth: "40px" }}>
            <LinkIcon />
          </ListItemIcon>
          <ListItemText
            primary="Koppellijst"
            primaryTypographyProps={{
              sx: { color: "white", fontSize: "14px" },
            }}
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ marginLeft: 3 }}>
        <ListItemButton
          component={Link}
          to={`/website/${websiteId}/ignoreList`}
          disabled={!websiteId}
        >
          <ListItemIcon sx={{ color: "white", minWidth: "40px" }}>
            <NotificationsPausedIcon />
          </ListItemIcon>
          <ListItemText
            primary="Negeerlijst"
            primaryTypographyProps={{
              sx: { color: "white", fontSize: "14px" },
            }}
          />
        </ListItemButton>
      </ListItem>
      <Divider sx={{ borderColor: "white" }} />
      <ListItem disablePadding>
        <ListItemButton onClick={handleLogout}>
          <ListItemIcon sx={{ color: "white", minWidth: "40px" }}>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText
            primary="Uitloggen"
            primaryTypographyProps={{
              sx: { color: "white", fontSize: "14px" },
            }}
          />
        </ListItemButton>
      </ListItem>
    </div>
  );
};

export default SideBarMenu;
