import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BaseDialog from "./UI/BaseDialog";
import { styled } from "@mui/system";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

const UploadImageDialog = ({
  openDialog,
  getImages,
  imageList,
  resetIndex,
}) => {
  const [componentMounted, setComponentMounted] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState(imageList);

  useEffect(() => {
    setSelectedImages(imageList);
  }, [imageList]);

  useEffect(() => {
    if (componentMounted) {
      setOpen(true);
    }
    setComponentMounted(true);
    // eslint-disable-next-line
  }, [openDialog]);

  function afterClose() {
    setOpen(false);
    if (imageList.length === 0) {
      setSelectedImages([]);
    }
  }

  const handleSave = () => {
    getImages(selectedImages);
    setOpen(false);
    resetIndex();
  };

  const handleImageChange = (event) => {
    const files = event.target.files;
    setSelectedImages((prevImages) => [...prevImages, ...Array.from(files)]);
  };

  const removeImage = (indexToRemove) => {
    setSelectedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(indexToRemove, 1);
      return updatedImages;
    });
  };

  const moveImage = (dragIndex, hoverIndex) => {
    setSelectedImages((prevImages) => {
      const updatedImages = [...prevImages];
      const [draggedImage] = updatedImages.splice(dragIndex, 1);
      updatedImages.splice(hoverIndex, 0, draggedImage);
      return updatedImages;
    });
  };

  const Image = ({ index, src, info }) => {
    const [, ref] = useDrag({
      type: "IMAGE",
      item: { index },
    });

    const [, drop] = useDrop({
      accept: "IMAGE",
      hover: (item) => {
        if (item.index !== index) {
          moveImage(item.index, index);
          item.index = index;
        }
      },
    });

    return (
      <div
        ref={(node) => ref(drop(node))}
        style={{
          margin: "8px",
          cursor: "move",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            borderRadius: "10px",
            width: "100px",
            height: "100px",
            overflow: "hidden",
          }}
        >
          <img
            src={src}
            alt={`Afbeelding ${index + 1}`}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {info.size / 1024 > 1000 ? (
            <small
              style={{
                color: info.size / 1024 > 300 ? "red" : "inherit",
              }}
            >
              {(info.size / 1024000).toFixed(1).replace(".", ",")} MB{" "}
            </small>
          ) : (
            <small
              style={{
                color: info.size / 1024 > 300 ? "red" : "inherit",
              }}
            >
              {(info.size / 1024).toFixed(1).replace(".", ",")} KB
            </small>
          )}

          <IconButton
            aria-label="delete"
            size="large"
            onClick={() => removeImage(index)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    );
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click(); // Trigger de klik op het input element
  };

  const content = (
    <Box>
      {selectedImages.length > 0 ? (
        <div>
          <Typography variant="h2">Bepaal de volgorde</Typography>
          <div style={{ display: "flex" }}>
            {selectedImages.map((image, index) => (
              <Image
                key={index}
                index={index}
                src={URL.createObjectURL(image)}
                info={image}
              />
            ))}
            <div
              style={{
                width: "100px",
                height: "100px",
                margin: "8px",
                display: "flex",
                alignItems: "center",
                marginLeft: 12,
              }}
            >
              {selectedImages.length < 8 && (
                <IconButton
                  aria-label="add"
                  size="large"
                  onClick={handleIconClick}
                >
                  <AddPhotoAlternateIcon style={{ transform: "scale(1.5)" }} />
                  <VisuallyHiddenInput
                    type="file"
                    ref={fileInputRef}
                    onChange={handleImageChange}
                    multiple
                  />
                </IconButton>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Box
          sx={{
            height: "300px",
            border: 3,
            borderColor: "primary.main",
            borderStyle: "dotted",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box textAlign="center">
            <Typography variant="h5">
              Upload afbeeldingen voor dit artikel
            </Typography>
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              sx={{ marginTop: 2 }}
            >
              Upload bestand
              <VisuallyHiddenInput
                type="file"
                onChange={handleImageChange}
                multiple
              />
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );

  return (
    <BaseDialog
      openDialog={open}
      afterClose={afterClose}
      handleSave={handleSave}
      disableHandleSave={selectedImages.length > 8}
      content={content}
    />
  );
};

const UploadImageDialogWithDnd = (props) => (
  <DndProvider backend={HTML5Backend}>
    <UploadImageDialog {...props} />
  </DndProvider>
);

export default UploadImageDialogWithDnd;
